import React, { useEffect, useState } from 'react'
import { SelectPicker, Input, HelpBlock, Button, Icon, Divider } from "rsuite"
import { connect } from "react-redux"
import * as action from "../../redux/actions/_language"
import AudioPlayer from 'react-h5-audio-player';
import { photos } from "../../contants/images"
import { motion } from "framer-motion"
function Form(props) {
    const { _getLanguage, language, option, setOption, selectedLang, setSelectedLang, setText, text, voice,
        t2sData, voiceData, gender, setGender, voiceLoading, setVoice, index, loading, textToSpeech, t, prevText, setPrevText
    } = props
    const [inputFocused, setInputFocused] = useState(false)


    useEffect(() => {
        _getLanguage()
        setOption(3)
    }, [])

    useEffect(() => {
        if (selectLanguage !== null && text !== '') {
            setOption(4)
        } else {
            setOption(3)
        }
    }, [selectedLang, text])


    const selectLanguage = (arg) => {
        setSelectedLang(arg)
    }

    // To Toggle the "Convert to Audio" Button when user change the script   
    useEffect(() => {
        if (t2sData !== null && text !== prevText) {
            setInputFocused(true)
        } else {
            setInputFocused(false)
        }
        if (prevText == text) {
            setInputFocused(false)
        }
    }, [text])

    // To Toggle the "Convert to Audio" Button when user change the script   
    useEffect(() => {
        if (t2sData !== null) {
            setPrevText(text)
        }
        if (prevText == text) {
            setInputFocused(false)
        }
    }, [t2sData])
    return (
        <div className="form">

            <motion.div className="input-field"
                initial={{ y: 40 }}
                animate={{ y: 0 }}
                transition={{ ease: "easeOut" }}>
                <label>{t('demo_language')}</label>
                <SelectPicker data={language ? language : []}
                    labelKey="name"
                    valueKey="name"
                    virtualized
                    cleanable={false}
                    onSelect={(a, b, c) => setSelectedLang(b)}
                    size="lg"
                    onSearch={(v) => console.log("Search: ", v)}
                />
            </motion.div>
            <div className="input-field">
                <label>{t('demo_select_gender')}</label>
                <div className="gender-div" >
                    <motion.div className={gender == 'MALE' ? "gender-box selected" : "gender-box"} onClick={() => setGender('MALE')}
                        initial={{ x: -40 }}
                        animate={{ x: 0 }}
                        transition={{ ease: "easeOut" }}>
                        <img className="radio" src={gender == "MALE" ? photos.radioActive : photos.radioUnctive} />
                        <img src={photos.men} />
                        <div>
                            <h5>{t('demo_gender')}</h5>
                            <h3>{t('demo_man')}</h3>
                        </div>

                    </motion.div>
                    <motion.div className={gender == 'FEMALE' ? "gender-box selected" : "gender-box"} onClick={() => setGender('FEMALE')}
                        initial={{ x: 40 }}
                        animate={{ x: 0 }}
                        transition={{ ease: "easeOut" }}
                    >
                        <img className="radio" src={gender == "FEMALE" ? photos.radioActive : photos.radioUnctive} />
                        <img src={photos.women} />
                        <div>
                            <h5>{t('demo_gender')}</h5>
                            <h3>{t('demo_woman')}</h3>
                        </div>
                    </motion.div>
                </div>
            </div>

            <motion.div className="input-field"
                initial={{ y: 120 }}
                animate={{ y: 0 }}
                transition={{ ease: "easeOut" }}
            >
                <label>{t('demo_select_voice')}</label>
                <SelectPicker
                    data={voiceData?.voices || []}
                    labelKey="replaced_name"
                    valueKey="replaced_name"
                    cleanable={false}
                    searchable={true}
                    defaultValue={t2sData !== null ? voiceData?.voices[0].replaced_name || "" : ""}
                    onSelect={(a, b, c) => setVoice(b)}
                    renderMenu={menu => {
                        if (voiceLoading) {
                            return (
                                <p style={{ padding: 4, color: '#f35d5e', textAlign: 'center' }}>
                                    <Icon icon="spinner" spin size="2x" />
                                </p>
                            )
                        }
                        return menu
                    }}
                />
            </motion.div>
            <motion.div className="input-field"
                initial={{ y: 160 }}
                animate={{ y: 0 }}
                transition={{ ease: "easeOut" }}
            >
                <label>{t('demo_text_to_play')}</label>
                <Input componentClass="textarea" rows={3} onChange={(v) => setText(v)} size="lg"
                />
                <HelpBlock>{t('demo_text_to_play_helper')}</HelpBlock>
                {index == 1 && t2sData !== null && inputFocused &&
                    <motion.div className="convert-again"
                        initial={{ height: 0 }}
                        animate={{ height: 50 }}
                        transition={{ ease: "easeOut", duration: 0.2, type: "tween" }}
                    >
                        <motion.div
                            initial={{ x: 30 }}
                            animate={{ x: 0 }}
                            transition={{ ease: "easeInOut", duration: 0.3 }}

                        >
                            <Button appearance="ghost" loading={loading} onClick={() => textToSpeech()}  >Convert to Audio</Button>
                        </motion.div>
                    </motion.div>}
            </motion.div>
            {t2sData != null &&
                <motion.div className="form__audioPlayer"
                    initial={{ y: 40 }}
                    animate={{ y: 0 }}
                    transition={{ ease: "easeOut" }}
                >
                    <div className="input-field" >
                        <label>Preview of the call</label>
                        <AudioPlayer src={t2sData.url} autoPlayAfterSrcChange={false} />

                    </div>

                </motion.div>

            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        language: state.LanguageReducer.data,
        loading: state.Text2SpeechReducer.loading,
        voiceData: state.VoicesReducer.data,
        voiceLoading: state.VoicesReducer.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        _getLanguage: () => dispatch(action.getLanguage()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Form)