import * as actionTypes from "../actionTypes"
import axios from "axios"


export const postApi = ({ dispatch }) => next => action => {
    if (action.type === actionTypes.POST_API_REQUEST) {
        const { method, url, onSuccess, onError, data, param } = action.meta;
        let sendingParams = action?.payload || undefined;
        console.log("Params: ", sendingParams)
        axios.defaults.headers.post['Content-Type'] = ['application/json']
        axios({
            url: url,
            method: method,
            params: {
                ...sendingParams
            },
            data: data,
            transformResponse: [(data) => {
                console.log('Data Response: ', JSON.parse(data))
            }],
        }).then(res => {
            console.log("Response: ", JSON.parse(res.request.response))
            if (res.request) {
                dispatch({ type: onSuccess, payload: JSON.parse(res.request.response) })
            }
        })
            .catch(error => {
                if (error.response) {
                    console.log("Error Response: ", error.response)
                    if (error.response.status >= 400 && error.response.status <= 500) {
                        let err = JSON.parse(error.response.request.response)
                        dispatch({ type: onError, payload: err })
                    }
                    else {
                        console.log("Error not Processed: ", error)
                    }
                }
            })
    }

    return next(action)
}
