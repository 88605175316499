import * as actionTypes from "../actionTypes"



export const getCountryInfo = () => ({
    type: actionTypes.GET_COUNTRY,
    payload: {}
})


export const getCountrySuccess = (data) => ({
    type: actionTypes.GET_COUNTRY_SUCCESS,
    payload: { data }
})



export const getCountryFail = (data) => ({
    type: actionTypes.GET_COUNTRY_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes
})


export const loaderFail = () => ({
    type: actionTypes
})