import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../api/_api"
import { loaderStart, loaderFail, sendOTPSuccess, sendOTPFail } from "../actions/_verifyMobile"
import history from "../../helpers/history"
import { Alert } from 'rsuite'


export const handleVerification = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SEND_OTP) {
        dispatch({ type: actionTypes.RESET_OTP })
        let data = action.payload.data;
        let onSuccess = actionTypes.SEND_OTP_SUCCESS;
        let onError = actionTypes.SEND_OTP_FAIL;
        dispatch(postApiRequest('POST', API.VERIFY_OTP, null, onSuccess, onError, data, null))
        dispatch(loaderStart())
    }
}

export const handleVerificationSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SEND_OTP_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(sendOTPSuccess(data))
        }
        dispatch(loaderFail())
    }
}

export const handleVerificationFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SEND_OTP_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(sendOTPFail(data))
        }
        dispatch(loaderFail())
    }
}




export const otpMdl = [
    handleVerification,
    handleVerificationSuccess,
    handleVerificationFail
]