import * as actionTypes from "../actionTypes"

export const handleUploadAudio = (data, option) => ({
    type: actionTypes.UPLOAD_AUDIO,
    payload: { data, option }
})

export const handleUpdateUploadAudioSuccess = (data) => ({
    type: actionTypes.UPDATE_UPLOAD_AUDIO_SUCCESS,
    payload: { data }
})

export const handleUpdateUploadAudioFail = (data) => ({
    type: actionTypes.UPDATE_UPLOAD_AUDIO_FAIL,
    payload: { data }
})


export const uploadAudioStart = () => ({
    type: actionTypes.UPLOAD_AUDIO_START
})

export const uploadAudioFinish = () => ({
    type: actionTypes.UPLOAD_AUDIO_FINISH
})