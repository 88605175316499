import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'



import Picon1 from "../../Assets/pertners/Logo.png"
import Picon2 from "../../Assets/pertners/Moodle.png"
import Picon3 from "../../Assets/pertners/Smartkart.png"
import Picon4 from "../../Assets/pertners/trans.png"
import Picon5 from "../../Assets/pertners/hoozing.png"




export const Partners = (props) => {
    const { t } = props

    return (
        <div className="centerDiv">
            <div className="w70">
                <div className="partnerDiv">
                    <h1 className="ptitle">{t('our_pertnar')}</h1>

                </div>
                <div className="pertsection">
                <img className="partnerImg" src={Picon5} />
                    <img className="partnerImg" src={Picon1} />
                    <img className="partnerImg" src={Picon2} />
                    <img className="partnerImg " src={Picon3} />
                    <img className="partnerImg" src={Picon4} />
                    {/* <img className="partnerImg" src={Skype} /> */}
                </div>
                {/* <Row>
                        <Col>
                            <img className="partnerImg" src={Paypal} />
                        </Col>
                        <Col>
                            <img className="partnerImg" src={Samsung} />
                        </Col>
                        <Col>
                            <img className="partnerImg ml-4" src={Intel} />
                        </Col>
                        <Col>
                            <img className="partnerImg" src={Google} />
                        </Col>
                        <Col>
                            <img className="partnerImg" src={Skype} />
                        </Col>
                    </Row> */}
            </div>
        </div>
    )
}

