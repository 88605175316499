import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../api/_api"
import { handleSuccess, handleFail, loaderStart, loaderFinish } from "../actions/_callStatus"
import history from "../../helpers/history"
import { Alert } from 'rsuite'


export const handleCallStatus = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CHECK_STATUS) {
        let data = action.payload.data;
        let onSuccess = actionTypes.CHECK_STATUS_SUCCESS;
        let onError = actionTypes.CHECK_STATUS_FAIL;
        dispatch(postApiRequest('GET', API.CHECK_STATUS, data, onSuccess, onError, null, null))
        dispatch(loaderStart())
    }
}

export const handleCallStatusSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CHECK_STATUS_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(handleSuccess(data))
        }
        dispatch(loaderFinish())
    }
}

export const handleCallStatusFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CHECK_STATUS_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(handleFail(data))
        }
        dispatch(loaderFinish())
    }
}




export const callStatusMdl = [
    handleCallStatus,
    handleCallStatusSuccess,
    handleCallStatusFail
]