import React,{Fragment} from 'react'
import {Footer} from "../footer/Footer"
import i18n from "../../i18next"
import { useTranslation } from "react-i18next"
export default function Payment() {
    const { t } = useTranslation()

    return (
        <Fragment>
        <div className="privacy" >
          <div className="container">
              <h1>CHÍNH SÁCH GIÁ VÀ THANH TOÁN:</h1>
              <div>
                  <p>
<strong>Quyền lợi cho doanh nghiệp</strong>
<p>hỗ trợ doanh nghiệp thay đổi gói dịch vụ dễ dàng, giao diện thân thiện với người dùng, bảo mật thông tin dữ liệu khách hàng và doanh nghiệp,…</p>
<strong>Giảm thiểu chi phí doanh nghiệp</strong>
<p>Hỗ trợ gói chi phí cài đặt hệ thống, giá thành canh tranh, tỉ lệ thành công cuộc gọi cao, đột phá doanh số, tối ưu hóa mối quan hệ giữa doanh nghiệp và khánh hàng</p>
<strong>Đa dạng gói dịch vụ</strong>
<p>Bảng giá đa dạng dành cho doanh nghiệp. Cung cấp các tính năng chuyên nghiệp, đưa ra nhiều sự chọn hợp lý cho từng yêu cầu của doanh nghiệp, tiết kiệm thời gian và chi phí,</p>
<strong>Phương thức thanh toán</strong>
<p>Thanh toán bằng tiền mặt: Số 8, đường 218 Cao Lỗ, phường 4, Quận 8, thành phố Hồ Chí Minh</p>
<p>Thanh toán bằng hình thức chuyển khoản:</p>
<p>Ngân hàng thương mại cổ phần Sài Gòn Thương Tín</p>
<p>Tên chủ tài khoản: Trần Tấn Mạnh</p>
<p>Số tài khoản: 060264663329</p>
</p>


              </div>
        </div>  

        </div>
        <Footer t={t} i18n={i18n} />    

        </Fragment>

    )
}
