import * as actionTypes from "../actionTypes"
import { updateObject } from "./utility"

const initialState = {
    loading: false,
    data: null,
    error: null
}



const updateFail = (action, state) => {
    return updateObject(state, {
        error: action.payload.data
    })
}

const updateSuccess = (action, state) => {
    return updateObject(state, {
        data: action.payload.data
    })
}

const LoaderStart = (action, state) => {
    return updateObject(state, {
        loading: true
    })
}
const LoaderOff = (action, state) => {
    return updateObject(state, {
        loading: false
    })
}

const resetVoice = (action, state) => {
    return updateObject(state, {
        data: null
    })
}

const VoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_GET_VOICES_SUCCESS: return updateSuccess(action, state);
        case actionTypes.UPDATE_GET_VOICES_FAIL: return updateFail(action, state);
        case actionTypes.GET_VOICES_START: return LoaderStart(action, state)
        case actionTypes.GET_VOICES_FINISH: return LoaderOff(action, state)
        case actionTypes.RESET_VOICE: return resetVoice(action, state)

        default:
            return state
    }
}



export default VoicesReducer