import * as actionTypes from "../actionTypes"



export const text2Speech = (data) => ({
    type: actionTypes.TEXT_TO_SPEECH,
    payload: { data }
})


export const handleSuccess = (data) => ({
    type: actionTypes.UPDATE_TEXT_TO_SPEECH_SUCCESS,
    payload: { data }
})



export const handleFail = (data) => ({
    type: actionTypes.UPDATE_TEXT_TO_SPEECH_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.TEXT_TO_SPEECH_START
})


export const loaderFinish = () => ({
    type: actionTypes.TEXT_TO_SPEECH_FINISH
})


export const resetFlow = () => ({
    type: actionTypes.RESET_THE_FLOW
})


