import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../api/_api"
import { getVoicesSuccess, getVoicesFail, getVoicesStart, getVoicesFinish } from "../actions/_voices"
import history from "../../helpers/history"
import { Alert } from 'rsuite'


export const handleVoices = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GET_VOICES) {
        // dispatch({ type: actionTypes.RESET_VOICE })
        let data = action.payload.data;
        let onSuccess = actionTypes.GET_VOICES_SUCCESS;
        let onError = actionTypes.GET_VOICES_FAIL;
        dispatch(postApiRequest('POST', API.GET_VOICES, null, onSuccess, onError, data, null))
        dispatch(getVoicesStart())
    }
}

export const handleVoicesSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GET_VOICES_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(getVoicesSuccess(data))
        }
        dispatch(getVoicesFinish())
    }
}

export const handleVoicesFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GET_VOICES_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(getVoicesFail(data))
        }
        dispatch(getVoicesFinish())
    }
}




export const voicesMdl = [
    handleVoices,
    handleVoicesFail,
    handleVoicesSuccess
]