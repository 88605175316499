import React from 'react'
import { Card, Button, Container, Col, Row } from 'react-bootstrap'
import shortlogo from "../../Assets/LogoShort.png"
import {Link} from 'react-router-dom'


export default function Product (props) {
    const { t } = props

    return (
        <div>
        <div className="whatIs mb-4">
        <h1 className="title color-red">{t('product_title')}</h1>
        </div>

        <div>
        <Container>
        <Row>

        <Col lg={4} className="d-flex align-items-lg-stretch mb-3">
        <Card className="card_border">
        <Card.Body>
            <Card.Title className="color-red font-weight-600 font-35 d-flex align-items-center justify-content-center">
            <img className="shortlogo" src={shortlogo} /> ENABLER</Card.Title>
            <Card.Text className="text-center mb-5">                      
           <span> {t('enabler_4')} </span> <b className="color-red">{t('enabler_1')}</b> 
                 <span>{t('enabler_5')}</span> 
              <b className="color-red"> {t('enabler_2')} </b>
              <span> {t('enabler_6')}</span>
                <b className="color-red"> {t('enabler_3')}.</b>
            </Card.Text>
            <div className="but">
            <a href="https://enabler.thevoicemate.com/" target="_blank" rel="noopener noreferrer" > <Button variant="light" className="mt-2  center-box">Learn More</Button></a>
         </div>
        </Card.Body>
        </Card>
        </Col>

        <Col lg={4} className="d-flex align-items-lg-stretch mb-3">
        <Card className="card_border">
        <Card.Body >
            <Card.Title className="color-red font-weight-600 font-35 text-center  d-flex align-items-center justify-content-center">
            <img className="shortlogo" src={shortlogo} /> AMPLIFY</Card.Title>
            <Card.Text className="text-center mb-5">
           <span>{t('amplify_1')}</span> 
           <b className="color-red"> {t('amplify_2')}</b> 
             <span>{t('amplify_3')}</span>
             <b className="color-red">{t('amplify_4')}</b> 
             <span>{t('amplify_5')}</span>
             <b className="color-red">{t('amplify_6')}</b> 
             <span>{t('amplify_7')}</span> 
             <b className="color-red">{t('amplify_8')} </b>
             <span>{t('amplify_9')}</span>

            </Card.Text>
            <div className="but">
            <a href="https://amplify.thevoicemate.com/" target="_blank" rel="noopener noreferrer" > <Button variant="light" className="mt-2  center-box">Learn More</Button></a>
         </div>
        </Card.Body>
        </Card>
        </Col>


        <Col lg={4} className="d-flex align-items-lg-stretch mb-3">
        <Card className="card_border">
        <Card.Body>
            <Card.Title className="color-red font-weight-600 font-35 text-center">
            <img className="shortlogo" src={shortlogo} />VALIDATOR</Card.Title>
            <Card.Text className="text-center mb-5">
            <span>{t('validator_1')}</span> 
            <b className="color-red">{t('validator_2')}</b>
             <span>{t('validator_3')}</span>
              <b className="color-red">{t('validator_4')}</b>
              <span>{t('validator_5')}</span> 
              <b className="color-red">{t('validator_6')}</b> 
              <span>{t('validator_7')}</span>
               <b className="color-red">{t('validator_8')}</b>
               <span>{t('validator_9')}</span>
            </Card.Text>
            <div className="but">
            <a href="https://validator.thevoicemate.com/" target="_blank" rel="noopener noreferrer"  ><Button variant="light" className="mt-2 center-box" >Learn More</Button></a>
         </div>
        </Card.Body>
        </Card>
        </Col>
        

        </Row>
        </Container>
        
        </div>
        
            
        </div>
    )
}

 
