const whileHover = {
    scale: 1.1,
    transition: {
        duration: 0.2
    }
}
const whileTap = {
    scale: 0.9,
    transition: {
        duration: 0.2
    }
}






export {
    whileHover,
    whileTap
}