import * as actionTypes from "../actionTypes"



export const getLanguage = () => ({
    type: actionTypes.GET_LANGUAGE,
})


export const updateLangSuccess = (data) => ({
    type: actionTypes.UPDATE_GET_LANGUAGE_SUCCESS,
    payload: { data }
})



export const updateLangFail = (data) => ({
    type: actionTypes.UPDATE_GET_LANGUAGE_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.GET_LANGUAGE_START
})


export const loaderFail = () => ({
    type: actionTypes.GET_LANGUAGE_FINISH
})