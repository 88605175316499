import * as actionTypes from "../actionTypes"



export const handleCallStatus = (data) => ({
    type: actionTypes.CHECK_STATUS,
    payload: { data }
})


export const handleSuccess = (data) => ({
    type: actionTypes.UPDATE_CHECK_STATUS_SUCCESS,
    payload: { data }
})



export const handleFail = (data) => ({
    type: actionTypes.UPDATE_CHECK_STATUS_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.CHECK_STATUS_START
})


export const loaderFinish = () => ({
    type: actionTypes.CHECK_STATUS_FINISH
})