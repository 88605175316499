import React from 'react'
import Contactform from "./Contactform"


function Contact(props) {
    const { t, i18n } = props

    return (
        <div>
            <div class="modal fade" data-keyboard="false" data-backdrop="static" id="contact" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog " role="document">
                    <div class="modal-content contact-model">
                        <Contactform t={t} i18n={i18n} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
