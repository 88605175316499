import React, { useState } from 'react'
import { photos } from "../../contants/images"
import { TiMediaRecord } from "react-icons/all"
import { Button } from "rsuite"
import { motion } from "framer-motion"
export default function SelectOption(props) {
    // const [option, setOption] = useState(0)
    const { option, setOption, t } = props
    return (
        <>
            <div className="selectOption" >
                <motion.div className={option == 1 ? "selectOption__box selected" : "selectOption__box"} onClick={() => setOption(1)}
                    onBlur={() => setOption(0)}
                    initial={{ x: -40 }}
                    animate={{ x: 0 }}
                    transition={{ ease: "easeOut" }}
                >
                    <img src={photos.tts} />
                    <p>{t("demo_create_a_question")}</p>
                </motion.div>
                <motion.div className={option == 2 ? "selectOption__box selected" : "selectOption__box"} onClick={() => setOption(2)}
                    onBlur={() => setOption(0)}
                    initial={{ x: 40 }}
                    animate={{ x: 0 }}
                    transition={{ ease: "easeOut" }}

                >
                    <img src={photos.records} />
                    <p>{t("demo_record_audio")}</p>
                </motion.div>
            </div>
            <div className="intro" >
                {/* <p>In this demo you can either type a <span>Question</span> or record an <span>Audio</span> , Which will be played to you on a phone
                call. Your response to the question will be shown <TiMediaRecord color="#C00000" />LIVE on the screen.
                </p> */}
                <p>{t("demo_intro")}</p>
                <div style={{ display: "flex", justifyContent: 'flex-end', paddingTop: 15 }} >
                    <Button appearance="ghost" data-dismiss="modal" >{t('demo_close')}</Button>
                </div>
            </div>
        </>
    )
}
