import * as actionTypes from "../actionTypes"
import axios from "axios"

export const authApi = ({ dispatch }) => next => action => {
    let token = localStorage.getItem('token');
    if (action.type === actionTypes.AUTH_API_REQUEST) {
        const { method, url, onSuccess, onError, data, param } = action.meta;
        // const {body}=action.payload
        const paramss = action.payload
        console.log("API Request Data: ", data)
        console.log("API Parmas: ", param)
        console.log("Body: ", action.payload)

        axios.interceptors.response.use(response => {
            // console.log("Response from Interceptor: ",response.request.response)   
            const res = response.request.response
            return response
        })

        axios({
            url: url,
            method: method,
            // baseURL:API.BASE_URI,
            headers: { Authorization: 'Bearer' + " " + token },
            params: {
                ...paramss
            },
            data: data,
            transformResponse: [(data) => {
                console.log('Data Response: ', JSON.parse(data))
            }],

        }).then(res => {
            console.log("Response: ", JSON.parse(res.request.response))
            if (res.request) {
                dispatch({ type: onSuccess, payload: JSON.parse(res.request.response) })
            }
        })
            .catch(error => {
                if (error.response) {
                    console.log("Error Response: ", error.response)
                    if (error.response.status >= 400 && error.response.status <= 500) {
                        let err = JSON.parse(error.response.request.response)
                        dispatch({ type: onError, payload: err })
                    }
                    else {
                        console.log("Error not Processed: ", error)
                    }
                }
            })
    }

    return next(action)
}




