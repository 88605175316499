import React, { useEffect } from 'react'
import { photos } from "../../contants/images"
import AudioPlayer from 'react-h5-audio-player';

export default function InitiateCall(props) {
    const { statusData } = props

    useEffect(() => {
        let changeTitle = document.getElementById('trailLabel')
        changeTitle.innerHTML = "Initiating your Call"
    }, [])
    return (
        <div className='initiateCall' >
            <div className="initiateCall__animation" >
                <img src={photos.calling} />
                <h3>{statusData?.call_status || "Getting Your Call Status"}</h3>

            </div>
            {statusData !== null && statusData.call_recorded_file !== null ?
                <div className="input-field" >
                    <AudioPlayer src={statusData.call_recorded_file} />
                </div> : <div />}
            {statusData !== null && statusData.user_response !== "" ?
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "row" }} >
                    <div className="input-field response-div">
                        <p>{statusData.user_response}</p>
                        <h4>Your Response </h4>
                    </div>
                </div> : <div />}
        </div>
    )
}
