import React, { useEffect } from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap'
import { Dropdown, List } from "rsuite"
import { GrLanguage } from "react-icons/all"
import { connect } from "react-redux"
import * as action from "../../redux/actions/_countryInfo"

function Header(props) {
    const { t, i18n, _getCountryInfo } = props


    useEffect(() => {
        _getCountryInfo()
    }, [])

    const changelanguage = (ln) => {
        return () => {
            i18n.changeLanguage(ln);
            console.log("I am change language", ln)
        }
    }

    return (
        <div>
            <div>
                <div className="centerDiv header">
                    <Navbar className="w70">
                        <Navbar.Brand href="#home" className="logo" >Voicemate</Navbar.Brand>
                        <Nav className="mr-auto">
                            <Nav.Link href="#home" className="headerLink">{t('head1')}</Nav.Link>
                            <Nav.Link href="#features" className="headerLink">{t('head2')}</Nav.Link>
                            <Nav.Link href="#benifit" className="headerLink">{t('head3')}</Nav.Link>
                            <Nav.Link href="#pricing" className="headerLink">{t('head4')}</Nav.Link>
                            <Nav.Link href="#pricing" className="headerLink">{t('head5')}</Nav.Link>
                        </Nav>
                        <div className="mr-2">
                            <GrLanguage />
                            <Dropdown title={t('language')} >
                                <Dropdown.Item onClick={changelanguage("en")} >English</Dropdown.Item>
                                <Dropdown.Item onClick={changelanguage("vi")} >Vietnamese</Dropdown.Item>
                            </Dropdown>

                        </div>
                        <div inline className="contact modal-btn" >
                            <button className="button" >{t('headbtn')}</button>
                        </div>
                    </Navbar>
                </div>
                <Navbar className="mobileHeader">
                    <div>
                        <p className="logo">Voicemate</p>
                    </div>
                    <div>
                        <p><i className="far fa-grip-lines"></i></p>
                    </div>
                </Navbar>


            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        _getCountryInfo: () => dispatch(action.getCountryInfo())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)