import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import Fb from "../../Assets/svg/fb.svg"
import { Dropdown } from 'rsuite'
import { Contact } from "../../views/contact"
import history from '../../helpers/history'
import {logo} from "../../contants/images"

export const Footer = (props) => {
    const { t, i18n } = props

    const changelanguage = (ln) => {
        return () => {
            i18n.changeLanguage(ln);
            console.log("I am change language", ln)
        }
    }


    return (
        <div>
            <div className="footerSec">
                <div className="centerDiv">
                    <div className="w70">
                        <Row>
                            <Col lg={4} sm={12}>
                                <div className="mobileFooter">
                                    <p className="footerTitle">{t('footer_follow')}</p>

                                    <div className="socialSec">
                                        <ul className="socialIcon">
                                            <li>
                                                <a href="https://www.facebook.com/thevoicemate/" target="_blank"><i class="fab fa-facebook-square"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/?hl=en" target="_blank"><i class="fab fa-instagram"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/voicemate" target="_blank"><i class="fab fa-linkedin"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="addSec mobileFooter">
                                    <p className="footerTitle">{t('footer_loc')}</p>
                                    <p className="address">{t('footer_add')}</p>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="pcfooterHide">


                                    <Row >
                                        <Col>
                                            <p className="footerTitle">{t('footer_voice')}</p>
                                            <div className="footerDiv">
                                                <ul>
                                                    <li><a href="#aboutus" className="footerText">{t('head1')}</a></li>
                                                </ul>
                                                <ul>
                                                    <li><a href="#whatisvoice" className="footerText">{t('voice_title')}</a></li>
                                                </ul> <ul>
                                                    <li><a href="#features" className="footerText">{t('diff_title')}</a></li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="footerDiv2">

                                                {/* <ul>
                                                    <li><a href="#" className="footerText">{t('head1')}</a></li>
                                                </ul> */}
                                                <ul>
                                                    <li><a href="#testimonial" className="footerText">{t('head5')}</a></li>
                                                </ul> <ul>
                                                    <li><a href="#benifit" className="footerText">{t('head3')}</a></li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="footerDiv1">
                                                {/* <button className="button modal-btn" type="button"
                                                >{t('btn_1')}</button> */}
                                                 <ul>
                                <li onClick={()=>history.push('/privacy-policy')}><a href="#"  className="footerText">{t('privacy_title')}</a></li>
                            </ul> 
                            <ul>
                                <li onClick={()=>history.push('/payment-policy')} ><a href="#" className="footerText">{t('payment_title')}</a></li>
                            </ul> 
                                                <ul>
                                                    <li><a href="#" data-toggle="modal" data-target="#contact" className="footerText contact modal-btn">{t('footer_cont')}</a></li>
                                                </ul>
                                                <ul>
                                                    <li><a href="#" className="footerText">{t('language_text')}:</a>
                                                        <Dropdown title={t('ln')} >
                                                            <Dropdown.Item onClick={changelanguage("en")} >EN</Dropdown.Item>
                                                            <Dropdown.Item onClick={changelanguage("vi")} >VI</Dropdown.Item>
                                                        </Dropdown></li>
                                                </ul>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                        </Row>


                    </div>
                </div>
            </div>
            <div className="mobileFt">
                <Row>
                    <Col >
                        <div>

                            <ul>
                                <li><a href="#" className="footerText">{t('privacy_title')}</a></li>
                            </ul> 
                            <ul>
                                <li><a href="#" className="footerText">{t('payment_title')}</a></li>
                            </ul> 
                            <ul>
                                <li><a href="#" className="footerText">{t('voice_title')}</a></li>
                            </ul> 
                            <ul>
                                <li><a href="#" className="footerText">{t('diff_title')}</a></li>
                            </ul>
                            <ul>
                                <li><a href="#" className="footerText">{t('language_text')}:</a>
                                    <Dropdown title={t('ln')} >
                                        <Dropdown.Item onClick={changelanguage("en")} >EN</Dropdown.Item>
                                        <Dropdown.Item onClick={changelanguage("vi")} >VI</Dropdown.Item>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                        <div className="">
                            <ul>
                                <li><a href="#" className="footerText">{t('head1')}</a></li>
                            </ul>
                            <ul>
                                <li><a href="#" className="footerText">{t('head4')}</a></li>
                            </ul>
                            <ul>
                                <li><a href="#" className="footerText">{t('head5')}</a></li>
                            </ul> <ul>
                                <li><a href="#" className="footerText">{t('head3')}</a></li>
                            </ul>
                            <ul>
                                <li><a href="#" className="footerText" data-toggle="modal" data-target="#contact" >{t('footer_cont')}</a></li>
                            </ul>
                        </div>

                    </Col>
                </Row>
            </div>
            <div className="lastFooter px-3">
                <p className="lastFooterText">{t('footer_policy')}</p>
                <div className="lastFooter__company  d-flex flex-column justify-content-center align-items-center" >
                <p className="lastFooterText"><strong>{t('footer_comp_1')}</strong></p>
                <p className="lastFooterText">{t('footer_comp_2')}</p>                                      
                <p className="lastFooterText">{t('footer_comp_3')}</p>                                      
                <p className="lastFooterText">{t('footer_comp_4')}</p>                                      
                </div>
                <div className="d-flex justify-content-center py-3" >
                <a 
                target="_blank"
                href="http://online.gov.vn/(X(1)S(iamvbst3empiivnwxsywbhec))/Home/WebDetails/79631?AspxAutoDetectCookieSupport=1" >
                <img src={logo.logoSaleNoti}  style={{height:100}} />

                </a>
                </div>
            </div>
            <Contact t={t} i18n={i18n} />
        </div>
    )
}

