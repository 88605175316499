import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../api/_api"
import { handleUpdateUploadAudioSuccess, handleUpdateUploadAudioFail, uploadAudioStart, uploadAudioFinish } from "../actions/_uploadAudio"
import history from "../../helpers/history"
import { Alert, Notification } from 'rsuite'


export const handleUploadAudio = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === actionTypes.UPLOAD_AUDIO) {
        let data = action.payload.data;
        let option = action.payload.option
        let onSuccess = actionTypes.UPLOAD_AUDIO_SUCCESS;
        let onError = actionTypes.UPLOAD_AUDIO_FAIL;
        if (option == 1) {
            dispatch(
                postApiRequest("POST", API.CONFIRM_AUDIO, null, onSuccess, onError, data, null)
            );
        }
        if (option == 2) {
            const formData = new FormData();
            formData.append('audio_file', data.audio_file);
            formData.append("lang_code", data.lang_code)
            dispatch(
                postApiRequest("POST", API.CONFIRM_AUDIO, null, onSuccess, onError, formData, null)
            );
        }
        dispatch(uploadAudioStart());
    }
};

export const handleUploadAudioSuccess = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === actionTypes.UPLOAD_AUDIO_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(handleUpdateUploadAudioSuccess(data));
        }
        dispatch(uploadAudioFinish());
    }
};

export const handleUploadAudioFail = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === actionTypes.UPLOAD_AUDIO_FAIL) {
        let error = action.payload;
        if (error) {
            const openNotification = () => {
                return Notification.error({
                    title: "Whopps !",
                    description: error.msg,
                });
            };
            openNotification();
            dispatch(handleUpdateUploadAudioFail(error));
        }
        dispatch(uploadAudioFinish());
    }
};




export const uploadAudioMdl = [
    handleUploadAudio,
    handleUploadAudioSuccess,
    handleUploadAudioFail
]