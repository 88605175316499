import React, { useEffect, useState } from 'react'
import { Input, InputGroup, SelectPicker, HelpBlock } from "rsuite"
import { connect } from "react-redux"
import { photos } from "../../contants/images"
import ISD from "./ISDCodes.json"
function VerifyMobile(props) {

    const { countryInfo, setMobile, data, error, setOtp, setIsd, prevNumber, setPrevNumber } = props


    return (
        <div className="verifyMobile" >
            <div className="input-field mobile">
                <label>Please enter your mobile number</label>
                <InputGroup>
                    <InputGroup.Addon>
                        <SelectPicker data={ISD} labelKey="dial_code" valueKey="dial_code" size="lg" cleanable={false}
                            defaultValue={countryInfo?.country_calling_code || ISD[0].dial_code} onSelect={(a, b, c) => setIsd(b)}
                        />
                    </InputGroup.Addon>
                    <Input placeholder="Mobile Number" size="lg" onChange={(v) => setMobile(v)} />
                </InputGroup>
                <HelpBlock>You will be receiving the Demo Call from Voicemate on this number.</HelpBlock>
            </div>
            {data !== null && <div className={error == null ? "success__msg-box" : "success__msg-box error"} >
                <img src={error == null ? photos.success : photos.attention} />
                <p className="heading">{error == null ? "OTP Sent Successfull !" : error}</p>
            </div>}
            <div className="input-field">
                <label>Enter your OTP</label>
                <Input placeholder="OTP" size="lg" disabled={data == null}
                    onChange={(v) => setOtp(v)}
                />
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        countryInfo: state.CountryReducer.data,
        data: state.OTPReducer.data
    }
}

export default connect(mapStateToProps, null)(VerifyMobile)
