import React, { useState, useEffect } from 'react'
import { Button, Steps, Modal } from "rsuite"
import { photos } from "../../contants/images"
import SelectOption from "./SelectOption"
import Form from "./Form"
import AudioRecord from "./Audio"
import VerifyMobile from "./VerifyMobile"
import InitiateCall from "./InitiateCall"
import Intro from "./Intro"
import { connect } from "react-redux"
import * as action from "../../redux/actions/_text2speech"
import * as uploadAction from "../../redux/actions/_uploadAudio"
import * as otpAction from "../../redux/actions/_verifyMobile"
import * as placeAction from "../../redux/actions/_placeCall"
import * as statusAction from "../../redux/actions/_callStatus"
import * as actionLang from "../../redux/actions/_language"
import * as voiceAction from "../../redux/actions/_voices"
import * as changeAction from "../../redux/actions/_change"
import { isMobile } from "react-device-detect"
import { GiSelect, IoMdCreate, BiMobileAlt, FiPhoneCall } from "react-icons/all"
function Trial(props) {

    const { t2sLoading, _text2speech, t2sData, uploadData, _uploadAudio, audioLoading, _resetFlow,
        countryInfo, _sendOTP, otpLoading, otpData, _placeCall, placeCallLoading, placeCallData, _callStatus, statusData,
        language, _getLanguage, _getVoices, voiceData, _handleChange, t, _callAgain
    } = props
    const [step, setSteps] = useState(0)
    const [index, setIndex] = useState(0)
    const [option, setOption] = useState(0)
    const [selectedLang, setSelectedLang] = useState(null)
    const [text, setText] = useState('')
    const [scriptName, setScriptName] = useState('')
    const [mobile, setMobile] = useState('')
    const [otp, setOtp] = useState('')
    const [verifyLoading, setVerifyLoading] = useState(false)
    const [error, setError] = useState(null)
    const [intervalId, setIntervalId] = useState(null)
    const [files, setFiles] = useState(null)
    const [isd, setIsd] = useState(null)
    const [gender, setGender] = useState('MALE')
    const [voice, setVoice] = useState(null)
    const [timer, setTimer] = useState(30)
    const [timerId, setTimerId] = useState(null)
    const [ttsId, setTtsid] = useState(false)
    const [prevText, setPrevText] = useState('')
    const [defaultVoice, setDefaultVoice] = useState({ language_code: "en-IN", gender: "MALE" })
    const [prevNumber, setPrevNumber] = useState('')
    const [isEditNumber, setIsEditNumber] = useState(false)

    //------------Move Back---------------
    const stepBack = () => {
        setIndex(0)
        setOption(0)
        resetFlow()
        setSteps(0)
        setVoice(null)
    }
    //----------Move Forward-------------
    const nextStep = () => {
        if (option == 1) {
            setIndex((prev) => prev + 1)
        }
        if (option == 2) {
            setIndex((prev) => prev + 2)
        }
    }

    //----------Text to Speech-------------------
    const textToSpeech = () => {
        let data = {
            text: text,
            voice_name: voice.original_name

        }
        _text2speech(data)
        setTtsid(true)
        setPrevText(text)
    }

    //----------Take to Mobile Verify-------------------

    useEffect(() => {
        if (uploadData !== null && statusData == null) {
            setIndex(3)
            setSteps(2)
        }
    }, [uploadData])

    //----------Whether to Record Audio or Create Script-------------------

    useEffect(() => {
        if (option === 1) {
            setIndex(1)
            setSteps(1)
        }
        if (option === 2) {
            setIndex(2)
            setSteps(1)
        }
    }, [option])


    const uploadAudio = () => {
        let data = {
            lang_code: selectedLang.short_code,
            audio_file: t2sData.audiofile_path,
            name: scriptName,
            duration: t2sData.duration
        }
        _uploadAudio(data, 1)
    }

    const audioFile = () => {
        let data = {
            audio_file: files.blob,
            lang_code: selectedLang.short_code
        }
        _uploadAudio(data, 2)
    }

    const sendOtp = () => {
        setPrevNumber(mobile)
        setIsEditNumber(false)
        let data = {
            phone_no: mobile,
            isd_code: isd !== null ? isd.dial_code : countryInfo.country_calling_code
        }
        setTimer(30)
        _sendOTP(data)
    }

    useEffect(() => {
        if (mobile !== prevNumber) {
            setIsEditNumber(true)
        }
        if (mobile == prevNumber) {
            setIsEditNumber(false)
        }
    }, [mobile])

    const verifyOtp = () => {
        setVerifyLoading(true)
        setTimeout(() => {
            if (otpData !== null && otp == otpData.otp_no) {
                setVerifyLoading(false)
                let data = {
                    call_id: uploadData.call_id,
                    isd_code: isd !== null ? isd.dial_code : countryInfo.country_calling_code,
                    phone_no: mobile
                }
                setSteps(3)
                _placeCall(data)
            } else {
                setError('Please Enter Correct OTP.')
                setVerifyLoading(false)
            }
        }, 2500)
    }

    const resetFlow = () => {
        _resetFlow()
        setIndex(0)
        setOption(0)
        setSteps(0)
        setSelectedLang(null)
        setError(null)
        setVoice(null)
    }

    useEffect(() => {
        if (placeCallData !== null && uploadData !== null) {
            setIndex(4)
            let data = {
                call_id: uploadData.call_id
            }
            let iId = setInterval(() => {
                _callStatus(data)
            }, 4000)
            console.log("Interval ID: ", intervalId)
            setIntervalId(iId)
        }
        if (t2sData == null) {
            console.log("Interval id after:", intervalId)
            clearInterval(intervalId)
        }
    }, [placeCallData, uploadData])

    useEffect(() => {
        if (statusData !== null) {
            if ((statusData !== null && statusData.call_recorded_file !== null) || (statusData.dial_status == 'CANCEL' || statusData.dial_status == 'CONGESTION')) {
                clearInterval(intervalId)
            }
        }

    }, [statusData])


    useEffect(() => {
        _getLanguage()
        _getVoices(defaultVoice)
    }, [])

    useEffect(() => {
        if (selectedLang !== null && voice !== null) {
            let data = {
                voice,
                selectedLang,
                gender,
                text,
                option: 1
            }
            _handleChange(data)
        }
    }, [gender, selectedLang])
    useEffect(() => {
        if (selectedLang !== null && voice !== null) {
            let data = {
                voice,
                selectedLang,
                gender,
                text,
                option: 2
            }
            _handleChange(data)
        }
    }, [voice])

    useEffect(() => {
        if (t2sData == null && selectedLang !== null) {
            let data = {
                language_code: selectedLang.short_code,
                gender: gender
            }
            _getVoices(data)
        }
    }, [selectedLang, gender])

    useEffect(() => {
        if (index == 3 && otpData !== null && timer == 30) {
            let id = setInterval(() => {
                setTimer((prev) => prev - 1)
            }, 1000)
            setTimerId(id)
        }
        if (timer == 0) {
            console.log("Interval id after:", timerId)
            clearInterval(timerId)
        }
    }, [index, otpData, timer])

    // useEffect(()=>{

    // },[])

    const callAgain = () => {
        clearInterval(intervalId)
        if (uploadData) {
            let data = {
                call_id: uploadData.call_id
            }
            _callAgain(data)
        }

    }

    return (
        <>
            <div className="modal fade" id="trail" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="trailLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header modal-header-trail">
                            <h5 className="modal-title modal-title-trail" id="trailLabel">{t("demo_let_start")}</h5>
                        </div>
                        <div className="modal-body modal-body-trail">
                            <Steps current={step}  >
                                <Steps.Item title={!isMobile && t("demo_select_option")} icon={<GiSelect />} />
                                <Steps.Item title={!isMobile && t("demo_create_question")} icon={<IoMdCreate />} />
                                <Steps.Item title={!isMobile && t("demo_verify_mobile")} icon={<BiMobileAlt />} />
                                <Steps.Item title={!isMobile && t("demo_get_call")} icon={<FiPhoneCall />} />
                            </Steps>
                            <div>
                                {index == 0 && <SelectOption option={option} setOption={setOption} t={t} />}
                                {index == 1 &&
                                    <Form
                                        option={option}
                                        setOption={setOption}
                                        text={text}
                                        setText={setText}
                                        setSelectedLang={setSelectedLang}
                                        selectedLang={selectedLang}
                                        t2sData={t2sData}
                                        uploadData={uploadData}
                                        scriptName={scriptName}
                                        setScriptName={setScriptName}
                                        gender={gender}
                                        setGender={setGender}
                                        setVoice={setVoice}
                                        index={index}
                                        textToSpeech={textToSpeech}
                                        t={t}
                                        voice={voice}
                                        prevText={prevText}
                                        setPrevText={setPrevText}
                                    />}
                                {index == 2 && <AudioRecord
                                    files={files}
                                    setFiles={setFiles}
                                    setScriptName={setScriptName}
                                    language={language}
                                    setSelectedLang={setSelectedLang}
                                    getLanguage={_getLanguage}
                                    t={t}
                                />}
                                {index == 3 &&
                                    <VerifyMobile
                                        mobile={mobile}
                                        setMobile={setMobile}
                                        setOtp={setOtp}
                                        error={error}
                                        setIsd={setIsd}
                                        prevNumber={prevNumber}
                                        setPrevNumber={setPrevNumber}
                                        t={t}
                                    />}
                                {index == 4 &&
                                    <InitiateCall
                                        statusData={statusData}
                                    />
                                }
                            </div>
                        </div>
                        {option !== 0 && <div className="modal-footer modal-footer-trail">
                            <div>
                                {index !== 0 && <Button appearance="subtle" onClick={stepBack} size="lg" >Home</Button>}
                            </div>
                            <div className="button-container" >
                                <div>
                                    <Button appearance="subtle" data-dismiss="modal" onClick={resetFlow} size="lg" >{t("demo_close")}</Button>
                                </div>
                                <div>
                                    {index == 0 && <Button type="button" appearance="primary" onClick={nextStep} size="lg"
                                        disabled={option == 0}
                                    >Next</Button>}
                                    {index == 1 && t2sData == null &&
                                        <Button type="button" appearance="primary" onClick={textToSpeech} style={{ width: 120 }}
                                            disabled={option == 3 || selectedLang == null || text == '' || voice == null} loading={t2sLoading} size="lg"
                                        >Confirm</Button>}
                                    {index == 1 && t2sData !== null &&
                                        <Button appearance="primary" onClick={uploadAudio}
                                            loading={audioLoading} size="lg"
                                        >Confirm</Button>}

                                    {index == 2 && t2sData == null &&
                                        <Button onClick={audioFile} appearance="primary" size="lg" loading={audioLoading}
                                            disabled={files == null || selectedLang == null}>Confirm</Button>
                                    }
                                    {index == 3 && otpData == null &&
                                        <Button appearance="primary" disabled={mobile.length < 7}
                                            onClick={sendOtp} loading={otpLoading} size="lg"
                                        >Send OTP</Button>}



                                    {index == 3 && otpData != null &&
                                        <Button appearance="ghost" disabled={timer !== 0} size="lg" style={{ marginRight: 10 }}
                                            onClick={sendOtp} loading={otpLoading && timer == 0}
                                        >Resend OTP{timer > 0 ? `(${timer})` : ""} </Button>}

                                    {index == 3 && otpData !== null && isEditNumber &&
                                        <Button appearance="primary" disabled={mobile.length < 7}
                                            onClick={sendOtp} loading={otpLoading} size="lg"
                                        >Send OTP</Button>}
                                    {index == 3 && otpData != null && !isEditNumber &&
                                        <Button appearance="primary" disabled={mobile.length < 7} size="lg"
                                            onClick={verifyOtp} loading={placeCallLoading || verifyLoading}
                                        >Verify OTP</Button>}

                                    {index == 4 && statusData !== null &&
                                        <Button appearance="primary" size="lg" onClick={callAgain} loading={placeCallLoading}  >Call Again </Button>
                                    }
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        t2sLoading: state.Text2SpeechReducer.loading,
        t2sData: state.Text2SpeechReducer.data,
        uploadData: state.UploadAudioReducer.data,
        audioLoading: state.UploadAudioReducer.loading,
        countryInfo: state.CountryReducer.data,
        otpLoading: state.OTPReducer.loading,
        otpData: state.OTPReducer.data,
        placeCallLoading: state.PlaceCallReducer.loading,
        placeCallData: state.PlaceCallReducer.data,
        callData: state.PlaceCallReducer.data,
        statusData: state.CallStatusReducer.data,
        language: state.LanguageReducer.data,
        voiceData: state.VoicesReducer.data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        _text2speech: (data) => dispatch(action.text2Speech(data)),
        _uploadAudio: (data, option) => dispatch(uploadAction.handleUploadAudio(data, option)),
        _sendOTP: (data) => dispatch(otpAction.sendOtp(data)),
        _resetFlow: () => dispatch(action.resetFlow()),
        _placeCall: (data) => dispatch(placeAction.placeCall(data)),
        _callStatus: (data) => dispatch(statusAction.handleCallStatus(data)),
        _getLanguage: () => dispatch(actionLang.getLanguage()),
        _getVoices: (data) => dispatch(voiceAction.getVoices(data)),
        _handleChange: (data) => dispatch(changeAction.handleChange(data)),
        _callAgain: (data) => dispatch(placeAction.callAgain(data))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Trial)