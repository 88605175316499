import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../api/_api"
import { handleSuccess, handleFail, loaderStart, loaderFinish } from "../actions/_contact"
import history from "../../helpers/history"
import { Alert } from 'rsuite'


export const handleContact = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CONTACT_US) {
        let data = action.payload.data;
        let onSuccess = actionTypes.CONTACT_US_SUCCESS;
        let onError = actionTypes.CONTACT_US_FAIL;
        dispatch(postApiRequest('POST', API.CONTACT_US, null, onSuccess, onError, data, null))
        dispatch(loaderStart())
    }
}

export const handleContactSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CONTACT_US_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(handleSuccess(data))
        }
        dispatch(loaderFinish())

    }
}

export const handleContactFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CONTACT_US_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(handleFail(data))
        }
        dispatch(loaderFinish())

    }
}




export const contactMdl = [
    handleContact,
    handleContactSuccess,
    handleContactFail
]