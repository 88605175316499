import * as actionTypes from "../actionTypes"



export const handleContact = (data) => ({
    type: actionTypes.CONTACT_US,
    payload: { data }
})


export const handleSuccess = (data) => ({
    type: actionTypes.UPDATE_CONTACT_US_SUCCESS,
    payload: { data }
})



export const handleFail = (data) => ({
    type: actionTypes.UPDATE_CONTACT_US_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.CONTACT_US_START
})


export const loaderFinish = () => ({
    type: actionTypes.CONTACT_US_FINISH
})