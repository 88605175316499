import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../api/_api"
import { getVoices } from "../actions/_voices"
import { text2Speech } from "../actions/_text2speech"
import history from "../../helpers/history"
import { Alert } from 'rsuite'
import { store } from "../store/index"

var globalData;

export const handleChange = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.HANDLE_CHANGE) {
        let data = action.payload.data
        globalData = data
        let a = data.selectedLang
        let postData = {
            language_code: a.short_code,
            gender: data.gender
        }
        dispatch(getVoices(postData))
    }
}

export const handleNext = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.UPDATE_GET_VOICES_SUCCESS) {
        const state = store.getState()
        let ttsData = state.Text2SpeechReducer.data
        let voice = state.VoicesReducer.data
        if (ttsData !== null && globalData.option == 1) {
            let data = {
                text: globalData.text,
                voice_name: voice.voices[0].original_name
            }
            dispatch(text2Speech(data))
        }
        if (ttsData !== null && globalData.option == 2) {
            let data = {
                text: globalData.text,
                voice_name: globalData.voice.original_name
            }
            dispatch(text2Speech(data))
        }
    }

}

// export const handle = ({ dispatch }) => next => action => {
//     next(action)
//     if (action.type === actionTypes) {

//     }
// }




export const changeMdl = [
    handleChange,
    handleNext
]