import * as actionTypes from "../actionTypes"
import * as API from "../../api/_api"
import { getCountrySuccess, getCountryFail } from "../actions/_countryInfo"
import i18n from "../../i18next"
import axios from "axios"

export const getCountryInfo = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GET_COUNTRY) {
        axios.get(API.COUNTRY_INFO)
            .then(res => {
                console.log("Country Info: ", res.data)
                dispatch(getCountrySuccess(res.data))
                if (res.data.country == "VN") {
                    i18n.changeLanguage('vi')
                }
            })
            .catch(err => {
                console.log("Country Fetch Failed:", err.msg)
                dispatch(getCountryFail(err.msg))
                i18n.changeLanguage('vi')
            })
    }
}

export const countryMdl = [
    getCountryInfo
]