import React, { useState } from 'react'
import Main from "../../Assets/svg/main2.svg"
import { Button, Steps, Input } from "rsuite"
import { Field, reduxForm } from "redux-form"
import { connect } from "react-redux"
import { photos } from "../../contants/images"
import * as action from "../../redux/actions/_contact"
const required = (value) =>
    value || typeof value === "number" ? undefined : "Required";

const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : undefined

const renderInput = (props) => {
    return (
        <div>
            <Input
                {...props.input}
                {...props}
                placeholder={props.placeholder}
                name={props.input.name}
                size="sm"
                block
            />
            <p style={{ color: "#FB5A2D" }}>{props.meta.touched ? props.meta.error : ""}</p>
        </div>
    );
};


function Contactform(props) {

    const { t, i18n, handleSubmit, valid, loading, data, _handleContact, error } = props




    const onSubmit = (value) => {
        console.log("Values: ", value)
        _handleContact(value)
    }

    return (
        <div>
            <div style={{ "border": "none" }} class="modal-header d-flex justify-content-center">
                <p class="modal-title contact-title" id="exampleModalLabel">{t('contact_title')}</p>

            </div>

            <div className="container">

                <form onSubmit={handleSubmit(onSubmit)} >
                    {data == null ? <>
                        <div className="row">
                            <div className="col-4">
                            </div>
                            <div className="col-8" style={{ "padding-right": "40px" }}>
                                <div className="lebel-section">
                                    <label className="label-name" style={{ "background-color": "#f2f2f2", "width": "100%", "text-align": "center", "padding": "6px", "border-radius": "4px" }}>
                                        {t('contact_name')} </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-8" style={{ "padding-left": "40px" }}>
                                <div className="lebel-section">
                                    <label className="label-name" style={{ "background-color": "#f2f2f2", "text-align": "center", "padding": "6px", "border-radius": "4px" }}>
                                        <Field component={renderInput} placeholder={t('contact_name_pls')} name="name"
                                            validate={required}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="d-flex justify-content-end">
                                    <img style={{ "width": "40px" }} src={Main} />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4">
                                <div className="d-flex justify-content-start">
                                    <i style={{ "font-size": "30px", "color": "#F64240" }} class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="col-8" style={{ "padding-right": "40px" }}>
                                <div className="lebel-section">
                                    <label className="label-name" style={{ "background-color": "#f2f2f2", "width": "100%", "text-align": "center", "padding": "6px", "border-radius": "4px" }}>
                                        {t('contact_email')} </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-8" style={{ "padding-left": "40px" }}>
                                <div className="lebel-section">
                                    <label className="label-name" style={{ "background-color": "#f2f2f2", "width": "100%", "text-align": "center", "padding": "6px", "border-radius": "4px" }}>
                                        <Field component={renderInput} placeholder={t('contact_email_pls')} name="email" validate={[required, email]} />

                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="d-flex justify-content-end">
                                    <img style={{ "width": "40px" }} src={Main} />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4">
                                <div className="d-flex justify-content-start">
                                    <i style={{ "font-size": "30px", "color": "#F64240" }} class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="col-8" style={{ "padding-right": "40px" }}>
                                <div className="lebel-section">
                                    <label className="label-name" style={{ "background-color": "#f2f2f2", "width": "100%", "text-align": "center", "padding": "6px", "border-radius": "4px" }}>
                                        {t('contact_text')} </label>
                                </div>
                            </div>
                        </div>


                        <div className="row mt-4">
                            <div className="col-8 " style={{ "padding-left": "40px" }}>
                                <div className="lebel-section">
                                    <label className="label-name" style={{ "background-color": "#f2f2f2", "text-align": "center", "padding": "6px", "border-radius": "4px" }}>
                                        <Field component={renderInput} placeholder={t('contact_text_pls')} name="message" componentClass="textarea" rows={3} validate={required} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-4 ">
                                <div className="d-flex justify-content-end">
                                    <img style={{ "width": "40px" }} src={Main} />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4">
                                <div className="d-flex justify-content-start">
                                    <i style={{ "font-size": "30px", "color": "#F64240" }} class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className="col-8" style={{ "padding-right": "40px" }}>
                                {/* <div className="lebel-section">
                                <label className="label-name" style={{ "background-color": "#f2f2f2", "width": "100%", "text-align": "center", "padding": "6px", "border-radius": "4px" }}>
                                    How can we help you? </label>
                            </div> */}
                            </div>
                        </div>
                    </> :

                        <div className={error == null ? "success__msg-box" : "success__msg-box error"} >
                            <img src={error == null ? photos.success : photos.attention} />
                            <p className="heading">{error == null ? "You query has been submitted successfuly to us. We will get back to you soon!" : error}</p>
                        </div>}


                    {/* <input type="submit" value="Submit" /> */}
                    <div className="d-flex justify-content-between pt-3">

                        <button data-dismiss="modal" type="button" class="rs-btn rs-btn-ghost">{t('contact_close')}</button>
                        {data == null && <Button type="submit" appearance="primary" disabled={!valid}
                            loading={loading}
                        >{t('contact_submit')}</Button>}
                    </div>
                </form>



            </div>

            <div class="modal-footer" style={{ "border": "none" }}>

            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        loading: state.ContactReducer.loading,
        data: state.ContactReducer.data,
        error: state.ContactReducer.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        _handleContact: (data) => dispatch(action.handleContact(data))
    }
}

Contactform = connect(mapStateToProps, mapDispatchToProps)(Contactform)

export default reduxForm({
    form: "Contact-Form"
})(Contactform)
