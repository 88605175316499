import React, { useState, useEffect } from 'react'
import { Input, SelectPicker } from "rsuite"
import { photos } from "../../contants/images"
import MicRecorder from "mic-recorder-to-mp3"
import AudioPlayer from 'react-h5-audio-player';
import { ReactMic } from '@cleandersonlobo/react-mic';

const AudioRecorder = new MicRecorder({ bitRate: 128 })

export default function AudioRecord(props) {
    const { files, setFiles, setScriptName, setSelectedLang, language, getLanguage } = props
    const [record, setRecord] = useState(0)
    const [start, setStart] = useState(false)

    const onStart = () => {
        setStart(true)
        setRecord(1)
    }
    const onStop = () => {
        setStart(false)

    }

    const onStopRec = (recordedBlob) => {
        console.log("Recorded Blob: ", recordedBlob)
        setFiles(recordedBlob)
        setRecord(2)
    }

    useEffect(() => {
        getLanguage()
    }, [])

    // const startRecording = () => {
    //     AudioRecorder.start().then((res) => {
    //         console.log("Recording: ", res)
    //         setRecord(1)
    //     }).catch((e) => {
    //         console.log("Start", e)

    //     })
    // }

    // const stopRecording = () => {
    //     AudioRecorder.stop().getMp3().then(([buffer, blob]) => {
    //         const file = new File(buffer, 'me-at-thevoice.mp3', {
    //             type: blob.type,
    //             lastModified: Date.now()
    //         });
    //         setFiles(file)
    //         setRecord(2)
    //         console.log("Recording: ", blob)
    //         const player = new Audio(URL.createObjectURL(file));
    //         console.log("Player: ", player)
    //     })
    // }

    return (
        <div className="audioRecord">
            <ReactMic
                record={start}
                className="sound-wave"
                onStop={onStopRec}
            />
            {record == 0 && <div className="audioRecord__rec-btn" onClick={onStart} >
                <img src={photos.rec} />
                <p>Record</p>
            </div>}
            {record == 1 && <div className="audioRecord__animation">
                <img src={photos.recording} />
            </div>
            }
            {record == 1 && <div className="audioRecord__rec-btn" onClick={onStop} >
                <img src={photos.stop} />
                <p>Stop</p>
            </div>}
            {record == 2 && files !== null &&
                <div className="audioRecord__player" >
                    <AudioPlayer src={files.blobURL} />
                    <div className="input-field" >
                        <label>Please Select Your Language</label>
                        <SelectPicker data={language ? language : []}
                            labelKey="name"
                            valueKey="name"
                            virtualized
                            searchable
                            onSelect={(a, b, c) => setSelectedLang(b)}
                        />
                    </div>
                    {/* <div className="input-field" >
                        <label>Script Name</label>
                        <Input placeholder="Write your script name" onChange={(v) => setScriptName(v)} />
                    </div> */}
                </div>}
        </div >
    )
}
