import * as actionTypes from "../actionTypes"


export const placeCall = (data) => ({
    type: actionTypes.PLACE_CALL,
    payload: { data }
})

export const placeCallSuccess = (data) => ({
    type: actionTypes.UPDATE_PLACE_CALL_SUCCESS,
    payload: { data }
})

export const placeCallFail = (data) => ({
    type: actionTypes.UPDATE_PLACE_CALL_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.PLACE_CALL_START
})

export const loaderFinish = () => ({
    type: actionTypes.PLACE_CALL_FINISH
})

export const callAgain = (data) => ({
    type: actionTypes.CALL_AGAIN,
    payload: { data }
})

export const callAgainSuccess = (data) => ({
    type: actionTypes.UPDATE_CALL_AGAIN_SUCCESS,
    payload: { data }
})

export const callAgainFail = (data) => ({
    type: actionTypes.UPDATE_CALL_AGAIN_FAIL,
    payload: { data }
})

export const callAgainStart = () => ({
    type: actionTypes.CALL_AGAIN_START
})

export const callAgainFinish = () => ({
    type: actionTypes.CALL_AGAIN_FINISH
})