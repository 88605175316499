import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import smart from "../../Assets/pertners/Smartkart.png"
import eazy from "../../Assets/pertners/Logo.png"
import moodle from "../../Assets/pertners/Moodle.png"
import preview4 from "../../Assets/preview/review4.jpg"





export const Testimonial = (props) => {
    const { t, i18n } = props


    return (
        <div>
            <div className="whatIs" id="testimonial">
                <h1 className="title2">{t('test_title')}</h1>
            </div>
            <div className="centerDiv">
                <div className="w70">
                    <OwlCarousel
                        className="owl-theme"
                        loop
                        items="1"

                        autoplay
                        dots
                        are not
                        margin={40}

                    >
                        {/* 1st */}
                        <div className="item">
                            <div className="itemDiv">
                                <div className="Lquote">
                                    <p><i className="fas fa-quote-left"></i></p>
                                </div>
                                <div className="tesDec">
                                    <p className="tesDec">
                                        In the pandemic, we were loosing on the customer touch points due to decrease in operational efficiency. With Voicemate, we were able to strategically increase the productivity with lower manforce.
                                    </p>
                                </div>
                                <div className="centerDiv pt-2">
                                    <p className="userName">Hien Nguyen </p>
                                </div>
                                <div className="centerDiv">
                                    <p className="userDg">VP-Operations</p>
                                </div>
                                <div className="Rquote">
                                    <p><i className="fas fa-quote-right"></i></p>
                                </div>

                            </div>
                            <div className="centerDiv mt-3">
                                <div className="userPicSec">
                                    <img className="userImg" src={smart} />
                                </div>
                            </div>
                        </div>
                        {/* 2nd */}
                        <div className="item">
                            <div className="itemDiv">
                                <div className="Lquote">
                                    <p><i className="fas fa-quote-left"></i></p>
                                </div>
                                <div className="tesDec">
                                    <p className="tesDec">
                                        The solution is so smooth and easy to use with negligible support required. Voicemate has been a greate platform to help us create Voice campagins on real time basis helping business to take quick decisions.
                                    </p>
                                </div>
                                <div className="centerDiv pt-2">
                                    <p className="userName">Truc Nguyen</p>
                                </div>
                                <div className="centerDiv">
                                    <p className="userDg">Head-Digital Marketing</p>
                                </div>
                                <div className="Rquote">
                                    <p><i className="fas fa-quote-right"></i></p>
                                </div>

                            </div>
                            <div className="centerDiv mt-3">
                                <div className="userPicSec">
                                    <img className="userImg" src={eazy} />
                                </div>
                            </div>
                        </div>
                        {/* 3rd */}
                        <div className="item">
                            <div className="itemDiv">
                                <div className="Lquote">
                                    <p><i className="fas fa-quote-left"></i></p>
                                </div>
                                <div className="tesDec">
                                    <p className="tesDec">
                                        It has always been a tedious task to reach out to candidates to understand their intend in the Job and further conduct first level of interviews. This has been a time taking Process. Voicemate is a wonerdfull application that helped us reduce efforts in the hiring process and the tools given us a better view on the intersted candidates further conducting autmated interviews to help us choose the best. Its truely the next level in Hiring Industry.
                                    </p>
                                </div>
                                <div className="centerDiv pt-2">
                                    <p className="userName">Bilyana Simpson </p>
                                </div>
                                <div className="centerDiv">
                                    <p className="userDg">Head-HR</p>
                                </div>
                                <div className="Rquote">
                                    <p><i className="fas fa-quote-right"></i></p>
                                </div>

                            </div>
                            <div className="centerDiv mt-3">
                                <div className="userPicSec">
                                    <img className="userImg" src={moodle} />
                                </div>
                            </div>
                        </div>

                        {/* 4th */}

                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

