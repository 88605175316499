import React from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import 'rsuite/lib/styles/index.less';
import 'react-h5-audio-player/lib/styles.css';
import { Body, Navbar, NewHeader,Privacy,Payment } from "./components/index"
import i18n from "./i18next"
import { useTranslation } from "react-i18next"
import {Router,Switch,Route} from 'react-router-dom'
import history from './helpers/history'
function App() {
  const { t } = useTranslation()

  return (
    <div className="App">
      <Router history={history} >
      <NewHeader t={t} i18n={i18n} />
      <Switch>
        <Route exact path="/" component={()=><Body t={t} i18n={i18n} />} />
        <Route exact path="/privacy-policy" component={()=><Privacy t={t} i18n={i18n} />} />
        <Route exact path="/payment-policy" component={()=><Payment t={t} i18n={i18n} />} />
      </Switch>
      </Router>

    </div>
  );
}

export default App;
