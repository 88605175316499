import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../api/_api"
import { placeCallSuccess, placeCallFail, loaderStart, loaderFinish, callAgainSuccess, callAgainFail } from "../actions/_placeCall"
import { handleUpdateUploadAudioSuccess } from "../actions/_uploadAudio"
import history from "../../helpers/history"
import { Alert } from 'rsuite'


export const placeCall = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.PLACE_CALL) {
        let data = action.payload.data;
        let onSuccess = actionTypes.PLACE_CALL_SUCCESS;
        let onError = actionTypes.PLACE_CALL_FAIL;

        dispatch(postApiRequest('POST', API.PLACE_CALL, null, onSuccess, onError, data, null))
        dispatch(loaderStart())

    }
}

export const handlePlaceCallSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.PLACE_CALL_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(placeCallSuccess(data))
        }
        dispatch(loaderFinish())
    }
}

export const handlePlaceCallFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.PLACE_CALL_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(placeCallFail(data))
        }
        dispatch(loaderFinish())
    }
}


export const callAgain = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CALL_AGAIN) {
        let data = action.payload.data;
        let onSuccess = actionTypes.CALL_AGAIN_SUCCESS;
        let onError = actionTypes.CALL_AGAIN_FAIL;
        dispatch(postApiRequest('POST', API.CALL_AGAIN, null, onSuccess, onError, data, null))
        dispatch(loaderStart())
    }
}

export const handleCallAgainSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CALL_AGAIN_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(handleUpdateUploadAudioSuccess(data))
        }
        dispatch(loaderFinish())
    }
}

export const handleCallAgainFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CALL_AGAIN_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(callAgainFail(data))
        }
        dispatch(loaderFinish())
    }
}


export const placeMdl = [
    placeCall,
    handlePlaceCallSuccess,
    handlePlaceCallFail,
    callAgain,
    handleCallAgainSuccess
]