import { api } from "./_api"
import { postApi } from "./_postApi"
import { authApi } from "./_authApi"
import { countryMdl } from "./_CountryInfo"
import { langMdl } from "./_Language"
import { t2sMdl } from "./_Text2Speech"
import { uploadAudioMdl } from "./_UploadAudio"
import { otpMdl } from "./_VerifyMobile"
import { placeMdl } from "./_PlaceCall"
import { callStatusMdl } from "./_CallStatus"
import { voicesMdl } from "./_Voices"
import { changeMdl } from "./_ChangeAsync"
import { contactMdl } from "./_Contact"
export const Middleware = [
    authApi,
    postApi,
    api,
    ...countryMdl,
    ...langMdl,
    ...t2sMdl,
    ...uploadAudioMdl,
    ...otpMdl,
    ...placeMdl,
    ...callStatusMdl,
    ...voicesMdl,
    ...changeMdl,
    ...contactMdl
]