import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import body from "../../Assets/svg/body.svg"
import MainSvg from "../../Assets/svg/main.svg"
import left from "../../Assets/svg/left.svg"
import One from "../../Assets/svg/1.svg"
import Two from "../../Assets/svg/2.svg"
import Three from "../../Assets/svg/3.svg"
import Four from "../../Assets/svg/4.svg"
import Five from "../../Assets/svg/5.svg"
import BenOne from "../../Assets/svg/ben-1.svg"
import BenTwo from "../../Assets/svg/ben-2.svg"
import BenThree from "../../Assets/svg/ben-3.svg"
import BenFour from "../../Assets/svg/ben-4.svg"
import BenFive from "../../Assets/svg/ben-5.svg"
import BenSix from "../../Assets/svg/ben-6.svg"
import BenSeven from "../../Assets/svg/ben-7.svg"
import UseOne from "../../Assets/svg/use-1.svg"
import UseTwo from "../../Assets/svg/use-2.svg"
import UseThree from "../../Assets/svg/use-3.svg"
import UseFour from "../../Assets/svg/use-4.svg"
import { Partners } from "../../components/partners/Partners"
import { Footer } from "../../components/footer/Footer"
import { Testimonial } from "../../components/testimonial/Testimonial"
// import Pricing from "../Pricing/Pricing"
import Product from "../Product/Product"
import { Trial, Plan } from "../../views"
import { photos } from "../../contants/images"
import { motion } from "framer-motion"
import { whileHover, whileTap } from "../../helpers/Animation"
const Body = (props) => {
    const [show, setShow] = useState(false)
    const [benifitData, setBenifitData] = useState(null)
    const [index, setIndex] = useState(0)
    const { t, i18n } = props

    useEffect(() => {
        let data = [
            {
                title: t('ben_icon1_btxt'),
                subTitle: t('ben_icon1_ntxt')
            },
            {
                title: t('ben_icon2_btxt'),
                subTitle: t('ben_icon2_ntxt')
            },
            {
                title: t('ben_icon3_btxt'),
                subTitle: t('ben_icon3_ntxt')
            },
            {
                title: t('ben_icon4_btxt'),
                subTitle: t('ben_icon4_ntxt')
            },
            {
                title: t('ben_icon5_btxt'),
                subTitle: t('ben_icon5_ntxt')
            },
            {
                title: t('ben_icon6_btxt'),
                subTitle: t('ben_icon6_ntxt')
            },
            {
                title: t('ben_icon7_btxt'),
                subTitle: t('ben_icon7_ntxt')
            }
        ]
        setBenifitData(data)
    }, [])


  

    return (
        <div >
            <div className="bodyDiv" id="aboutus">


                <h4 className="smlTitle" style={{ textTransform: "uppercase" }} >{t('title')}</h4>
                <div className="centerDiv">
                    <div className="maintitsec">
                        <h1 >{t('main_title')} </h1>
                    </div>

                </div>
                <div className="centerDiv">
                    <div className="MsgBox">
                        <p>
                            {t('main_dec')}
                        </p>
                    </div>
                </div>
                <div className="centerDiv btnSec">
                    <div className="boxSvg">
                        <motion.div whileHover={whileHover} whileTap={whileTap} >
                            <button  
                            className="button modal-btn" 
                            type="button" 
                            data-toggle="modal" data-target="#trail"
                            >{t('primaryButton')}</button>
                        </motion.div>
                        <div>
                            <a href="#usecase" className="learn">{t('btn_2')}</a>
                        </div>
                    </div>
                </div>
                <div className="centerDiv">
                    <div className="bgSec">
                        <div className="bannerDiv">
                            <img src={photos.tabBar} alt="img" className="tabBar" />
                            <img src={photos.banner_anime} alt="img" />
                        </div>
                        <div className="bg-blue-mobile">

                        </div>
                    </div>
                </div>
            </div>
            <div className="whatIs voice" id="whatisvoice" >
                <h1>{t('voice_title')}</h1>
            </div>
            <div className="container text-center pt-3 px-3">
                <p>
                    {t('voice_dec')}
                </p>
            </div>
            <div className="centerDiv btnSec2">
                <div className="boxSvg">
                    <motion.div whileHover={whileHover} whileTap={whileTap} >
                        <button 
                        data-toggle="modal" data-target="#trail"
                        className="button modal-btn" >{t('primaryButton')}</button>
                    </motion.div>
                    <div>
                        <a href="#usecase" className="learn">{t('btn_2')}</a>
                    </div>
                </div>
            </div>
            <div className="centerDiv">
                <div className="mainSVG">
                    <img className="mainsvgPic" src={photos.voicemate_solo} alt="img" />
                </div>
            </div>

            {/*------- different Section-------- */}
            <div className="Section" id="features" >
                <div className="whatIs">
                    <h1>{t('diff_title')}</h1>
                </div>
                <img className="leftSvg" src={left} alt="img" />
                <div className="centerDiv">
                    <div className="w70 pt-3">
                        <div >
                            <Row>
                                <Col sm={12} md={6} lg={6}>
                                    <div className="IconDiv">
                                        <div>
                                            <img className="proIcon" src={One} alt="img" />
                                        </div>
                                        <div>
                                            <h4 className="IconText">{t('diff_icon1_title')}</h4>
                                            <p className="IconDec">{t('diff_icon1_dec')}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <div className="IconDiv">
                                        <div>
                                            <img className="proIcon" src={Two} alt="img" />
                                        </div>
                                        <div>
                                            <h4 className="IconText">{t('diff_icon2_title')}</h4>
                                            <p className="IconDec">{t('diff_icon2_dec')}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="IconTop">
                                <Col sm={12} md={6} lg={6}>
                                    <div className="IconDiv">
                                        <div>
                                            <img className="proIcon" src={Three} alt="img" />
                                        </div>
                                        <div>
                                            <h4 className="IconText">{t('diff_icon3_title')}</h4>
                                            <p className="IconDec">{t('diff_icon3_dec')}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <div className="IconDiv">
                                        <div>
                                            <img className="proIcon" src={Four} alt="img" />
                                        </div>
                                        <div>
                                            <h4 className="IconText">{t('diff_icon4_title')}</h4>
                                            <p className="IconDec">{t('diff_icon4_dec')}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="IconTop">
                                <Col sm={12} md={6} lg={6}>
                                    <div className="IconDiv">
                                        <div>
                                            <img className="proIcon" src={Five} alt="img" />
                                        </div>
                                        <div>
                                            <h4 className="IconText">{t('diff_icon5_title')}</h4>
                                            <p className="IconDec">{t('diff_icon5_dec')}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            {/*------- different Section end-------- */}

            {/*------- Benefits Section -------- */}
            <div className="Section" id="benifit" >


                <img className="rightSvg" src={left} alt="img" />
                <div className="centerDiv">
                    <div className="w70">
                        <div className="whatIs py-3">
                            <h1>{t('ben_title')}</h1>
                            <p className="benDec text-center">{t('ben_dec')}</p>

                        </div>
                        <div className="benSec">
                        </div>
                        <div className="pcView">
                            <Row>
                                <Col>
                                    <div className="IconDiv">
                                        <div>
                                            <img src={BenOne} alt="img" />
                                        </div>
                                        <div>
                                            <p className="benTextSec"><span className="benText">{t('ben_icon1_btxt')}</span><span className="benTextsml"> {t('ben_icon1_ntxt')}</span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="IconDiv">
                                        <div>
                                            <img src={BenTwo} alt="img" />
                                        </div>
                                        <div>
                                            <p className="benTextSec"><span className="benText">{t('ben_icon2_btxt')}</span><span className="benTextsml"> {t('ben_icon2_ntxt')}</span></p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="IconSec">
                                <Col>
                                    <div className="IconDiv">
                                        <div>
                                            <img src={BenThree} alt="img" />
                                        </div>
                                        <div>
                                            <p className="benTextSec"><span className="benText">{t('ben_icon3_btxt')}</span><span className="benTextsml"> {t('ben_icon3_ntxt')}</span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="IconDiv">
                                        <div>
                                            <img src={BenFour} alt="img" />
                                        </div>
                                        <div>
                                            <p className="benTextSec"><span className="benText">{t('ben_icon4_btxt')}</span><span className="benTextsml"> {t('ben_icon4_ntxt')}</span></p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="IconSec">
                                <Col>
                                    <div className="IconDiv">
                                        <div>
                                            <img src={BenFive} alt="img" />
                                        </div>
                                        <div>
                                            <p className="benTextSec"><span className="benText">{t('ben_icon5_btxt')}</span><span className="benTextsml"> {t('ben_icon5_ntxt')}</span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="IconDiv">
                                        <div>
                                            <img src={BenSix} alt="img" />
                                        </div>
                                        <div>
                                            <p className="benTextSec2"><span className="benText">{t('ben_icon6_btxt')}</span><span className="benTextsml"> {t('ben_icon6_ntxt')}</span>
                                                {/* <span className="benTextsml"> {t('ben_icon6b_ntxt')}</span> */}

                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="IconSec">
                                <Col>
                                    <div className="IconDiv">
                                        <div>
                                            <img src={BenSeven} alt="img" />
                                        </div>
                                        <div>
                                            <p className="benTextSec"><span className="benText">{t('ben_icon7_btxt')}</span><span className="benTextsml"> {t('ben_icon7_ntxt')}</span></p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                        </div>

                        {/* //--------------Mobile Benifit-------------- */}
                        {benifitData !== null && <div className="mobileView">
                            <div className="result">
                                <p className="benTextSec"><span className="benText">{benifitData[index].title}</span><span className="benTextsml"> {benifitData[index].subTitle}</span></p>
                            </div>
                            <Row>
                                <Col>
                                    <div className={index == 0 ? "IconDiv-benifit active" : "IconDiv-benifit"} onClick={() => setIndex(0)} >
                                        <div>
                                            <img src={BenOne} alt="img" />
                                        </div>

                                    </div>
                                </Col>
                                <Col>
                                    <div className={index == 1 ? "IconDiv-benifit active" : "IconDiv-benifit"} onClick={() => setIndex(1)} >
                                        <div>
                                            <img src={BenTwo} alt="img" />
                                        </div>

                                    </div>
                                </Col>
                                <Col>
                                    <div className={index == 2 ? "IconDiv-benifit active" : "IconDiv-benifit"} onClick={() => setIndex(2)}>
                                        <div>
                                            <img src={BenThree} alt="img" />
                                        </div>

                                    </div>
                                </Col>
                            </Row>

                            <Row className="IconSec">

                                <Col>
                                    <div className={index == 3 ? "IconDiv-benifit active" : "IconDiv-benifit"} onClick={() => setIndex(3)}>
                                        <div>
                                            <img src={BenFour} alt="img" />
                                        </div>

                                    </div>
                                </Col>
                                <Col>
                                    <div className={index == 4 ? "IconDiv-benifit active" : "IconDiv-benifit"} onClick={() => setIndex(4)}>
                                        <div>
                                            <img src={BenFive} alt="img" />
                                        </div>

                                    </div>
                                </Col>
                                <Col>
                                    <div className={index == 5 ? "IconDiv-benifit active" : "IconDiv-benifit"} onClick={() => setIndex(5)}>
                                        <div>
                                            <img src={BenSix} alt="img" />
                                        </div>

                                    </div>
                                </Col>
                            </Row>

                            <Row className="IconSec">
                                <Col>
                                    <div className={index == 6 ? "IconDiv-benifit active" : "IconDiv-benifit"} onClick={() => setIndex(6)}>
                                        <div>
                                            <img src={BenSeven} alt="img" />
                                        </div>

                                    </div>
                                </Col>
                                <Col>

                                </Col>
                                <Col>

                                </Col>
                            </Row>

                        </div>}
                    </div>
                </div>
            </div>
            {/*------- Benefits Section end -------- */}
            <div className="whatIs" id="usecase">
                <h1>{t('cas_title')}</h1>
            </div>
            {/* --------------use one ----------- */}
            <div className="UseSection">
                <div className="centerDiv">
                    <div className="w70">
                        <Row className="RVS">
                            <Col className="" lg={6} md={6} sm={12}>
                                <div className="mobileTop">
                                    <h4 className="useTitle">{t('cas_out_title')}</h4>
                                    <ul className="list">
                                        <li>{t('cas_out_1')}</li>
                                        <li>{t('cas_out_2')}</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col className="" lg={6} md={6} sm={12}>
                                <div className="rightImg ">
                                    <img className="useIcon1" src={UseOne} alt="img" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/* ----------------- use two--------------- */}
            <div className="UseSection">
                <div className="centerDiv">
                    <div className="w70">
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="imgLeft">
                                    <img className="useIcon2" src={UseTwo} alt="img" />
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="mobileTop">
                                    <h4 className="useTitle">{t('cas_ser_title')}</h4>
                                    <ul className="list">
                                        <li>{t('cas_ser_1')}</li>
                                        <li>{t('cas_ser_2')}</li>
                                        <li>{t('cas_ser_3')}</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            {/* ------------------ use three ----------------- */}
            <div className="UseSection">
                <div className="centerDiv">
                    <div className="w70">
                        <Row className="RVS">
                            <Col lg={6} md={6} sm={12}>
                                <div className="mobileTop">
                                    <h4 className="useTitle">{t('cas_coll_title')}</h4>
                                    <ul className="list">
                                        <li>{t('cas_coll_1')}</li>
                                        <li>{t('cas_coll_2')}</li>
                                        <li>{t('cas_coll_3')}</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="rightImg">
                                    <img className="useIcon3" src={UseThree} alt="img" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            {/* -------------------- use four ------------------- */}
            <div className="UseSection">
                <div className="centerDiv">
                    <div className="w70">
                        <Row>

                            <Col lg={6} md={6} sm={12}>
                                <div className="rightImg">
                                    <img className="useIcon4" src={UseFour} alt="img" />
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="mobileTop">
                                    <h4 className="useTitle">{t('cas_rec_title')}</h4>
                                    <ul className="list">
                                        <li>{t('cas_rec_1')}</li>
                                        <li>{t('cas_rec_2')}</li>
                                        <li>{t('cas_rec_3')}</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div>
                {/* <Pricing t={t} i18n={i18n} /> */}
                <Product t={t} i18n={i18n} />
            </div>
            <div>
                <Testimonial t={t} i18n={i18n} />
            </div>

            <div>
                <Partners t={t} i18n={i18n} />
            </div>
            <div>
                <Footer t={t} i18n={i18n} />
            </div>
            <Trial t={t} i18n={i18n} />
            <Plan t={t} i18n={i18n} />
        </div>
    )
}

export default Body;

