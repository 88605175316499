const logo = {
    logoShort: require('../Assets/LogoShort.png'),
    logo: require('../Assets/logo.png'),
    logoSaleNoti:require('../Assets/logoSaleNoti.png')
}



const photos = {
    document: require('../Assets/documents.svg'),
    mic: require('../Assets/microphone.svg'),
    recording: require('../Assets/recording.gif'),
    rec: require('../Assets/rec.svg'),
    stop: require('../Assets/stop.svg'),
    success: require('../Assets/success.svg'),
    attention: require('../Assets/attention.svg'),
    calling: require('../Assets/Calling.gif'),
    banner_anime: require('../Assets/Final.gif'),
    tabBar: require('../Assets/tabBar.png'),
    voicemate_solo: require('../Assets/voicemateSingle.gif'),
    men: require('../Assets/man.svg'),
    women: require('../Assets/woman.svg'),
    tts: require('../Assets/tts.svg'),
    records: require('../Assets/records.svg'),
    radioActive: require('../Assets/radio-active.svg'),
    radioUnctive: require('../Assets/radio-unactive.svg'),

    // voicemate: require('../Assets/voicemate.svg')
}


export {
    logo,
    photos
}