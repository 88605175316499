import React, { useState, useEffect } from 'react'
import { HiOutlineMenuAlt3, AiOutlineClose, IoIosLogOut } from "react-icons/all"
import { Button, Dropdown } from "rsuite"
import { logo } from "../../contants/images"
import { connect } from "react-redux"
import * as action from "../../redux/actions/_countryInfo"
import history from "../../helpers/history"


function NewHeader(props) {
    const [isClicked, setClicked] = useState(false)
    const { t, i18n, _getCountryInfo } = props


    useEffect(() => {
        _getCountryInfo()
    }, [])

    const changelanguage = (ln) => {
        return () => {
            i18n.changeLanguage(ln);
            console.log("I am change language", ln)
        }
    }

    return (
        <div className="navbar" >
            <nav className="navbar__main" >
                <div className="navbar__brand-box" onClick={()=>history.push('/')} >
                    <img src={logo.logo} />
                </div>
                <div className="navbar__menu-icon">
                    {!isClicked ? <HiOutlineMenuAlt3 size={40} onClick={() => setClicked(!isClicked)} color="#f35d5e" /> :
                        <AiOutlineClose size={40} onClick={() => setClicked(!isClicked)} color="#f35d5e" />}
                </div>

                <ul className={isClicked ? "navbar__menu active" : "navbar__menu"} >
                    <li  ><a className="navbar__menu__links" href="#aboutus" >{t('head1')}</a></li>
                    <li ><a className="navbar__menu__links" href="#features" >{t('head2')}</a></li>
                    <li  ><a className="navbar__menu__links" href="#benifit" >{t('head3')}</a></li>
                    <li  ><a className="navbar__menu__links" href="#usecase" >{t('head4')}</a></li>
                    <li  ><a className="navbar__menu__links" href="#testimonial" >{t('head5')}</a></li>

                    {isClicked && <Dropdown title={t('language')} >
                        <Dropdown.Item onClick={changelanguage("en")} >English</Dropdown.Item>
                        <Dropdown.Item onClick={changelanguage("vi")} >Vietnamese</Dropdown.Item>
                    </Dropdown>}
                    {isClicked && 
                    <Button appearance="ghost" block size="lg" className="contact modal-btn">Sign Up</Button>
                    }
                </ul>
                <div className="navbar__cta-box" >
                    <Dropdown title={t('language')} >
                        <Dropdown.Item onClick={changelanguage("en")} >English</Dropdown.Item>
                        <Dropdown.Item onClick={changelanguage("vi")} >Vietnamese</Dropdown.Item>
                    </Dropdown>
                    <Button appearance="primary" className="contact modal-btn"  size="lg" >Sign Up</Button>
                </div>
            </nav>
        </div>

    )
}



const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        _getCountryInfo: () => dispatch(action.getCountryInfo())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader)