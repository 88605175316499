import React from 'react'

export default function Plan(props) {
    const { t } = props
    return (
        <div className="modal fade" id="planBox" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="planBox" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="trailLabel">Connect with Us</h5>
                    </div>
                    <div className="modal-body">

                    </div>
                </div>
            </div>
        </div>
    )
}
