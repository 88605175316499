import * as actionTypes from "../actionTypes"



export const sendOtp = (data) => ({
    type: actionTypes.SEND_OTP,
    payload: { data }
})


export const sendOTPSuccess = (data) => ({
    type: actionTypes.UPDATE_SEND_OTP_SUCCESS,
    payload: { data }
})



export const sendOTPFail = (data) => ({
    type: actionTypes.UPDATE_SEND_OTP_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.SEND_OTP_START
})


export const loaderFail = () => ({
    type: actionTypes.SEND_OTP_FINISH
})