/** @format */

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as formReducer } from "redux-form";
import CountryReducer from "./countryReducer"
import LanguageReducer from "./languageReducer"
import Text2SpeechReducer from "./text2speechReducer"
import UploadAudioReducer from "./uploadAudioReducer"
import OTPReducer from "./OTPReducer"
import PlaceCallReducer from "./placeReducer"
import CallStatusReducer from "./callStatusReducer"
import VoicesReducer from "./voicesReducer"
import ContactReducer from "./contactReducer"
const Reducers = combineReducers({
  CountryReducer,
  LanguageReducer,
  Text2SpeechReducer,
  UploadAudioReducer,
  OTPReducer,
  PlaceCallReducer,
  CallStatusReducer,
  VoicesReducer,
  ContactReducer,
  form: formReducer,
});

export default Reducers;
