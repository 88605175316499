/** @format */

//To request api
export const API_REQUEST = "API_REQUEST";
export const POST_API_REQUEST = "POST_API_REQUEST";
export const AUTH_API_REQUEST = "AUTH_API_REQUEST";


//--------------To get the country info----------

export const GET_COUNTRY = "GET_COUNTRY"
export const GET_COUNTRY_START = "GET_COUNTRY_START"
export const GET_COUNTRY_FINISH = "GET_COUNTRY_FINISH"
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS"
export const GET_COUNTRY_FAIL = "GET_COUNTRY_FAIL"


//--------------To get the List of Language--------------

export const GET_LANGUAGE = "GET_LANGUAGE"
export const GET_LANGUAGE_START = "GET_LANGUAGE_START"
export const GET_LANGUAGE_FINISH = "GET_LANGUAGE_FINISH"
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS"
export const GET_LANGUAGE_FAIL = "GET_LANGUAGE_FAIL"
export const UPDATE_GET_LANGUAGE_FAIL = "UPDATE_GET_LANGUAGE_FAIL"
export const UPDATE_GET_LANGUAGE_SUCCESS = "UPDATE_GET_LANGUAGE_SUCCESS"


//------------------------Get Voices----------------------
export const GET_VOICES = "GET_VOICES"
export const GET_VOICES_START = "GET_VOICES_START"
export const GET_VOICES_FINISH = "GET_VOICES_FINISH"
export const GET_VOICES_SUCCESS = "GET_VOICES_SUCCESS"
export const GET_VOICES_FAIL = "GET_VOICES_FAIL"
export const UPDATE_GET_VOICES_FAIL = "UPDATE_GET_VOICES_FAIL"
export const UPDATE_GET_VOICES_SUCCESS = "UPDATE_GET_VOICES_SUCCESS"
export const RESET_VOICE = "RESET_VOICE"

//--------------To convert text to audio-------------------

export const TEXT_TO_SPEECH = "TEXT_TO_SPEECH";
export const TEXT_TO_SPEECH_START = "TEXT_TO_SPEECH_START";
export const TEXT_TO_SPEECH_FINISH = "TEXT_TO_SPEECH_FINISH";
export const TEXT_TO_SPEECH_SUCCESS = "TEXT_TO_SPEECH_SUCCESS";
export const TEXT_TO_SPEECH_FAIL = "TEXT_TO_SPEECH_FAIL";
export const UPDATE_TEXT_TO_SPEECH_SUCCESS = "UPDATE_TEXT_TO_SPEECH_SUCCESS";
export const UPDATE_TEXT_TO_SPEECH_FAIL = "UPDATE_TEXT_TO_SPEECH_FAIL";
export const RESET_SPEECH = "RESET_SPEECH"

//--------------------COFIRM-AUDIO-----------------------

export const UPLOAD_AUDIO = "UPLOAD_AUDIO"
export const UPLOAD_AUDIO_START = "UPLOAD_AUDIO_START"
export const UPLOAD_AUDIO_FINISH = "UPLOAD_AUDIO_FINISH"
export const UPLOAD_AUDIO_SUCCESS = "UPLOAD_AUDIO_SUCCESS"
export const UPLOAD_AUDIO_FAIL = "UPLOAD_AUDIO_FAIL"
export const UPDATE_UPLOAD_AUDIO_FAIL = "UPDATE_UPLOAD_AUDIO_FAIL"
export const UPDATE_UPLOAD_AUDIO_SUCCESS = "UPDATE_UPLOAD_AUDIO_SUCCESS"

//-----------------SEND OTP---------------------------

export const SEND_OTP = "SEND_OTP"
export const SEND_OTP_START = "SEND_OTP_START"
export const SEND_OTP_FINISH = "SEND_OTP_FINISH"
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"
export const SEND_OTP_FAIL = "SEND_OTP_FAIL"
export const UPDATE_SEND_OTP_SUCCESS = "UPDATE_SEND_OTP_SUCCESS"
export const UPDATE_SEND_OTP_FAIL = "UPDATE_SEND_OTP_FAIL"
export const RESET_OTP = "RESET_OTP"

//-----------------PLACE CALL-----------------------------

export const PLACE_CALL = "PLACE_CALL"
export const PLACE_CALL_START = "PLACE_CALL_START"
export const PLACE_CALL_FINISH = "PLACE_CALL_FINISH"
export const PLACE_CALL_SUCCESS = "PLACE_CALL_SUCCESS"
export const PLACE_CALL_FAIL = "PLACE_CALL_FAIL"
export const UPDATE_PLACE_CALL_FAIL = "UPDATE_PLACE_CALL_FAIL"
export const UPDATE_PLACE_CALL_SUCCESS = "UPDATE_PLACE_CALL_SUCCESS"



//------------------CHECK STATUS---------------------------
export const CHECK_STATUS = "CHECK_STATUS"
export const CHECK_STATUS_START = "CHECK_STATUS_START"
export const CHECK_STATUS_FINISH = "CHECK_STATUS_FINISH"
export const CHECK_STATUS_SUCCESS = "CHECK_STATUS_SUCCESS"
export const CHECK_STATUS_FAIL = "CHECK_STATUS_FAIL"
export const UPDATE_CHECK_STATUS_FAIL = "UPDATE_CHECK_STATUS_FAIL"
export const UPDATE_CHECK_STATUS_SUCCESS = "UPDATE_CHECK_STATUS_SUCCESS"

export const RESET_THE_FLOW = "RESET_THE_FLOW"


//-----------MISECELLENIOUS--------------------------
export const HANDLE_CHANGE = "HANDLE_CHANGE"


//-------------------CALL AGAIN-----------------------------
export const CALL_AGAIN = "CALL_AGAIN"
export const CALL_AGAIN_START = "CALL_AGAIN_START"
export const CALL_AGAIN_FINISH = "CALL_AGAIN_FINISH"
export const CALL_AGAIN_SUCCESS = "CALL_AGAIN_SUCCESS"
export const CALL_AGAIN_FAIL = "CALL_AGAIN_FAIL"
export const UPDATE_CALL_AGAIN_FAIL = "UPDATE_CALL_AGAIN_FAIL"
export const UPDATE_CALL_AGAIN_SUCCESS = "UPDATE_CALL_AGAIN_SUCCESS"



//-------------------Contact Us-----------------------------

export const CONTACT_US = "CONTACT_US"
export const CONTACT_US_START = "CONTACT_US_START"
export const CONTACT_US_FINISH = "CONTACT_US_FINISH"
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS"
export const CONTACT_US_FAIL = "CONTACT_US_FAIL"
export const UPDATE_CONTACT_US_FAIL = "UPDATE_CONTACT_US_FAIL"
export const UPDATE_CONTACT_US_SUCCESS = "UPDATE_CONTACT_US_SUCCESS"