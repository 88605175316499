import React,{Fragment} from 'react'
import {Footer} from "../footer/Footer"
import i18n from "../../i18next"
import { useTranslation } from "react-i18next"
export default function Privacy() {
    const { t } = useTranslation()

    return (
        <Fragment>
        <div className="privacy" >
          <div className="container">
              <h1>CHÍNH SÁCH BẢO MẬT THÔNG TIN</h1>
              <div>
                  <p>
<strong>1. Mục đích thu thập thông tin cá nhân</strong>
<p>Mục đích của việc thu thập thông tin khách hàng nhằm liên quan đến các vấn đề như:</p>
<p>– Hỗ trợ khách hàng: tư vấn các dịch vụ của chúng tôi.</p>
<p>– Cung cấp thông tin các dịch vụ và hỗ trợ theo yêu cầu của khách hàng.</p>
<p>– Gửi thông báo các chương trình, dịch vụ mới nhất của chúng tôi.</p>
<p>– Giải quyết vấn đề phát sinh khi sử dụng dịch vụ.</p>
<p>– Ngăn chặn các hoạt động phạm pháp.</p>
<p>– Đo lường và cải thiện các dịch vụ của chúng tôi.</p>
</p>
<p><strong>2. Phạm vi thu thập thông tin</strong></p>
<p>Chúng tôi thu thập thông tin cá nhân của khách hàng khi:</p>
<p>– Khách hàng trực tiếp cung cấp: Khách hàng trực tiếp cung cấp bao gồm: họ tên, địa chỉ email, số điện thoại, địa chỉ.</p>
<p>– Khách hàng tương tác với chúng tôi: Chúng tôi sử dụng cookies và công nghệ theo dấu khác để thu thập một số thông tin khi khách hàng tương tác trên website <a href="https://thevoicemate.com/"  >https://thevoicemate.com/</a></p>
<p>– Từ những nguồn hợp pháp khác: Chúng tôi thu thập thông tin khách hàng từ các nguồn hợp pháp khác.</p>

<p><strong>3. Thời gian lưu trữ thông tin</strong></p>
<p>Công Ty TNHH Công Nghệ VOICEMATE sẽ lưu trữ các thông tin cá nhân do khách hàng cung cấp trên các hệ thống nội bộ của chúng tôi trong quá trình cung cấp dịch vụ cho khách hàng hoặc khi khách hàng có yêu cầu hủy thông tin đã cấp.</p>

<p><strong>4. Những người hoặc tổ chức có thể được tiếp cận với thông tin đó</strong></p>

<p>– Các đối tác là bên cung cấp dịch vụ cho chúng tôi liên quan đến thực hiện đơn hàng và chỉ giới hạn trong phạm vi thông tin cần thiết cũng như áp dụng các quy định đảm bảo an ninh, bảo mật các thông tin cá nhân.</p>
<p>– Chúng tôi sử dụng dịch vụ từ một nhà cung cấp dịch vụ là bên thứ ba để thực hiện một số hoạt động liên quan đến website  <a href="https://thevoicemate.com/">https://thevoicemate.com/</a> . Khi đó, bên thứ ba có thể truy cập hoặc xử lý các thông tin cá nhân trong quá trình cung cấp các dịch vụ đó. Chúng tôi yêu cầu các bên thứ ba này tuân thủ mọi luật lệ về bảo vệ thông tin cá nhân liên quan và các yêu cầu về an ninh liên quan đến thông tin cá nhân.</p>
<p>– Các chương trình có tính liên kết, đồng thực hiện, thuê ngoài cho các mục đích được nêu tại Mục 1 và luôn áp dụng các yêu cầu bảo mật thông tin cá nhân.</p>
<p>– Yêu cầu pháp lý: Chúng tôi có thể tiết lộ các thông tin cá nhân nếu điều đó do luật pháp yêu cầu và việc tiết lộ như vậy là cần thiết một cách hợp lý để tuân thủ các quy trình pháp lý.</p>
<p>– Chuyển giao kinh doanh (nếu có): trong trường hợp sáp nhập, hợp nhất toàn bộ hoặc một phần với công ty khác, người mua sẽ có quyền truy cập thông tin được chúng tôi lưu trữ, duy trì trong đó bao gồm cả thông tin cá nhân.</p>


<p><strong>5. Địa chỉ của đơn vị thu thập và quản lý thông tin</strong></p>

<p>– Tên doanh nghiệp: Công Ty TNHH Công Nghệ VOICEMATE</p>
<p>– Thành lập và hoạt động theo Giấy chứng nhận đăng ký doanh nghiệp số: 0316715597 Do Sở KH & ĐT Thành phố Hồ Chí Minh cấp ngày 17/02/2021</p>
<p>– Trụ sở chính: Số 8, đường 218 Cao Lỗ, phường 4, Quận 8, thành phố Hồ Chí Minh</p>
<p><strong>6. Phương thức và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu:</strong></p>

<p>Nếu quý khách có bất cứ về yêu cầu nào về việc tiếp cận và chỉnh sửa thông tin cá nhân đã cung cấp, quý khách có thể:</p>
<p>– Gọi điện trực tiếp về số điện thoại: 0939 469 074</p>
<p>– Gửi mail: <a href="mailto:manh@thevoicemate.com" >manh@thevoicemate.com</a></p>

              </div>
        </div>  

        </div>
        <Footer t={t} i18n={i18n} />    

        </Fragment>

    )
}
