

export const COUNTRY_INFO = "https://ipapi.co/json/"


export const LANGUAGE_LIST = "/api/campaigns/get-languages/"
export const TEXT_TO_SPEECH = "/api/campaigns/gtts/"
export const CONFIRM_AUDIO = "/api/manage-calls/confirm-audio/"
export const VERIFY_OTP = "/api/manage-calls/verify-no/"
export const CHECK_STATUS = "/api/manage-calls/call-status/"
export const PLACE_CALL = "/api/manage-calls/place-call/"
export const GET_VOICES = "/api/campaigns/get-voices/"
export const CALL_AGAIN = "/api/manage-calls/call-again/"
export const CONTACT_US = "/api/manage-calls/contact-us/"
