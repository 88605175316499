import React, { useState, useEffect } from 'react'
import { Toggle as Switch } from "rsuite"
import PropTypes from 'prop-types';
import { motion } from "framer-motion"
const Toggle = ({ size, checkedChildren, unCheckedChildren, checked, disabled, onChange }) => {
    const [active, setActive] = useState(false)

    useEffect(() => {
        console.log("Checked: ", checked)
        setActive(checked)
    }, [checked])

    const changeActive = () => {
        console.log("Onlci")
        setActive(!active)
        onChange(active)
    }

    return (
        <div className="toggle">
            <div className="toggle__box"  >
                <div className={!active ? "toggle__box__left active" : "toggle__box__left"} onClick={changeActive} >
                    <p>{unCheckedChildren}</p>
                    <motion.div className={!active ? "toggle__box__slider" : "toggle__box__slider animate"}>

                    </motion.div>
                </div>
                <div className={active ? "toggle__box__right active" : "toggle__box__right"} onClick={changeActive} >
                    <p>{checkedChildren}</p>
                </div>
            </div>
        </div>
    )
}




Toggle.propTypes = {
    checkedChildren: PropTypes.string.isRequired,
    unCheckedChildren: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string
};

Toggle.defaultProps = {
    size: "md",
    checked: false
};


export default Toggle