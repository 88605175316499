import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest } from "../actions/api"
import * as API from "../../api/_api"
import { handleSuccess, handleFail, loaderStart, loaderFinish } from "../actions/_text2speech"
import history from "../../helpers/history"
import { Alert, Notification } from 'rsuite'


export const textToSpeech = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === actionTypes.TEXT_TO_SPEECH) {
        dispatch({ type: actionTypes.RESET_SPEECH })
        let data = action.payload.data;
        let onSuccess = actionTypes.TEXT_TO_SPEECH_SUCCESS;
        let onError = actionTypes.TEXT_TO_SPEECH_FAIL;
        dispatch(
            postApiRequest("POST", API.TEXT_TO_SPEECH, null, onSuccess, onError, data, null)
        );
        dispatch(loaderStart());
    }
};

export const textToSpeechSuccess = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === actionTypes.TEXT_TO_SPEECH_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(handleSuccess(data));
        }
        dispatch(loaderFinish());
    }
};

export const textToSpeechFail = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === actionTypes.TEXT_TO_SPEECH_FAIL) {
        let error = action.payload;
        if (error) {
            const openNotification = () => {
                return Notification.error({
                    title: "Whopps !",
                    description: error.msg,
                });
            };
            openNotification();
            dispatch(handleFail(error));
        }
        dispatch(loaderFinish());
    }
};




export const t2sMdl = [
    textToSpeech,
    textToSpeechSuccess,
    textToSpeechFail
]